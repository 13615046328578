.PageLayout {
	margin-top: 65px;
}
.c-main > .container-fluid {
	padding-right: 0;
	padding-left: 0;
}

.layout-container {
	margin-top: 20;
	display: flex;
	flex-direction: row;
	padding-left: 0;
}
.row-container {
	display: flex;
	flex-direction: row;
}
.inbox-item {
	flex: 2;
}
.msg-item {
	flex: 4;
	min-width: 60vw;
}
.detail {
	box-sizing: border-box;
	height: 156px;
	width: 440px;
	border: 1px solid #e1e6ed;
	background-color: #fbfbfb;
	min-height: 80px;
}
.email {
	height: 14px;
	width: 142px;
	color: #686871;
	font-family: Montserrat;
	font-size: 11px;
	letter-spacing: 0.18px;
	line-height: 14px;
}
.SaveButton {
	background: #ff9e00 !important;
	color: #ffffff;
	margin-left: auto;
	padding: 10px 100px 10px 100px;
	margin-right: 10px;
}

.CancelButton {
	background: #ffffff;
	color: #8f9bb3;
	margin-right: auto;
	padding: 10px 100px 10px 100px;
}

/******* personal information    ********/
button.edit_bttn_option.btn.btn-pill {
	display: inline-block;
	float: right;
	text-decoration: underline;
}
.PI_page .load-class {
	position: absolute;
	width: 100px;
	height: 100px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
/*.PI_page .load-class.load_change{
  transform: translate(-50%,-17%);
}*/
.contain_body {
	position: relative;
}
.PI_page .PageLayout {
	margin-top: 100px;
}
