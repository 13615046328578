.Sidebar {
	background: #404040;
	margin-top: 95px;
}

.c-sidebar {
	.c-sidebar-nav-link.c-active,
	.c-active.c-sidebar-nav-dropdown-toggle {
		color: #fff;
		background: #635846;
		border-top: solid 7px #ff9300;
		svg {
			color: #ff9300 !important;
		}
	}
}

.top_navOuter {
	.navbar-brand {
		padding-bottom: 1px;
	}
}

.c-show svg {
	color: #ff9300 !important;
}

.c-show .c-sidebar-nav-dropdown-toggle {
	background: #635846;
	border-top: solid 7px #ff9300;
}

.c-show .c-sidebar-nav-link.c-active {
	border-top: none;
}

.c-sidebar-nav {
	> li {
		> a {
			font-weight: 700;
			font-size: 15px;
			padding: 20px 10px;
			border-bottom: solid 1px #fff;
			color: white !important;
			position: relative;
			&:hover {
				background-color: #635846 !important;
			}
		}
		a {
			&:hover {
				background-color: #635846 !important;
			}
		}
	}
}

.c-sidebar-minimized {
	> .c-sidebar-nav {
		> li {
			> a {
				padding: 20px 15px !important;
			}
		}
		.c-sidebar-nav-dropdown-items {
			.c-active {
				border-top: none !important;
			}
		}
		.c-sidebar-nav-dropdown.c-show {
			> a {
				border-top: solid 7px #ff9300;
				background-color: #635846;
			}
			svg {
				color: #ff9300 !important;
			}
		}
	}
}

.unread-messages-icon > .c-sidebar-nav-link::after {
	content: "";
	display: table;
	height: 12px;
	width: 12px;
	background: #ff9300;
	border-radius: 50%;
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
}

.unread-messages-icon > .c-sidebar-nav-link::before {
	content: "";
	display: table;
	height: 8px;
	width: 8px;
	background: #ff9300;
	border-radius: 50%;
	position: absolute;
	left: 27px;
	top: 40%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
