@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

.news-style-one {
	position: relative;
	margin-bottom: 60px;
	float: left !important;
	font-family: "Heebo", sans-serif;
	color: #666666;
}

.news-style-one .inner-box {
	position: relative;
	display: block;
}

.news-style-one .inner-box .image-box img {
	display: block;
	width: 100%;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.news-style-one .inner-box:hover .image-box img {
	opacity: 0.7;
}

.news-style-one .lower-content {
	position: relative;
	padding: 25px 0px 0px;
}

.news-style-one .lower-content .post-meta {
	line-height: 24px;
	color: #696969;
	font-size: 16px;
	margin-bottom: 10px;
}

.news-style-one .lower-content h3 {
	font-size: 20px;
	font-weight: 500;
	color: #3b566e;
	margin-bottom: 12px;
}

.news-style-one .lower-content h3 a {
	color: #3b566e;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.news-style-one .lower-content h3 a:hover {
	color: #1f8ceb;
}

.news-style-one .lower-content .text {
	line-height: 1.7em;
}

.news-style-one .lower-content .text p {
	line-height: 1.7;
	margin-bottom: 20px;
}

.news-style-one blockquote {
	font-size: 18px;
	font-style: italic;
	padding: 22px 30px;
	background: #f1f1f1;
	border-left: 5px solid #1f8ceb;
	margin: 30px 0px 30px 50px;
}

.main-banner {
	position: relative;
	z-index: 1;
}

/*Banner One*/
.banner-one {
	position: relative;
	padding: 120px 10px 100px 35px;
	background-position: left top;
	background-repeat: no-repeat;
	background-size: cover;
	margin-left: 40px;
	margin-right: 40px;
}

.banner-one .text-column,
.banner-one .image-column {
	margin-bottom: 30px;
}

.banner-one .text-column .inner {
	position: relative;
	padding-top: 100px;
}

.banner-one .text-column h4 {
	font-size: 18px;
	color: #1f8ceb;
	text-transform: capitalize;
	margin-bottom: 30px;
}

.banner-one .text-column h2 {
	font-size: 36px;
	font-weight: 300;
	color: #3b566e;
	margin-bottom: 15px;
}

.banner-one .text-column p {
	font-size: 18px;
	margin-bottom: 50px;
}

.auto-container {
	position: static;
	max-width: 1200px;
	padding: 0px 15px;
	margin: 0 auto;
}

.text p {
	color: #666666 !important;
	font-size: 18px;
	font-family: "Heebo", sans-serif;
}

.text span {
	/* color: #3c4b64 !important; */
	font-size: 18px;
}

.blog-title {
	font-family: "Montserrat", sans-serif;
	font-size: 3rem;
}

.date-style {
	color: #666666 !important;
	font-size: 16px;
	font-family: "Heebo", sans-serif;
}
