//fonts
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import "react-circular-progressbar/dist/styles.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/css/font-awesome.css");

// Global
$quadFi-orange: #ff9e00;
$quadFi-dark: rgba(255, 147, 0, 0.3);
$quadFi-red: tomato;

a {
	color: $quadFi-orange;
}

img {
	user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.center {
	text-align: center;
}

.left-align {
	text-align: left;
}

.readable {
	line-height: 3rem;
}

.scrollable {
	height: 600px;
	max-width: 900px;
	overflow: auto;
	padding: 1rem;
}

.highlight {
	color: #ff9300;
}

.spaced {
	> * {
		margin-bottom: 1rem;
	}
}

.product-icon {
	height: 500px;
	width: 100px;
}

.error-container {
	color: tomato;
	position: relative;
}

.error-validation {
	color: tomato;
	position: relative;
}

.error-message {
	position: absolute;
	padding: 1rem;
}

.custom-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	margin: 1rem;
	background: #ececec;
	border: 3px solid rgba(255, 147, 0, 0.3);
	box-shadow: 4px 8px 15px rgba(0, 0, 0, 0.15);
	border-radius: 7px;

	img {
		width: 150px;
		height: 150px;
		margin: 1rem;
	}

	button {
		width: 80%;
		padding: 7px;
		margin: 1rem;
		color: white;
		background-color: #222222;
		font-weight: 600;
		border-radius: 4px;
		outline: none;
		border: none;
	}
}

section {
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.wrapper {
	padding: 2rem;
}

// RANDOM STUFF
#page-section {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white;
}

.resendEmailButton {
	cursor: pointer;
	border: solid 1px;
	padding: 3px 6px;
	border-radius: 5px;
	background-color: orange;
	color: white;
	font-weight: 600;
	margin-left: 7px;
}
#intro-section {
	flex: 1;
	display: flex;
	flex-direction: column;
	background-color: white;

	h1 {
		font-size: 50px;
	}
	h3 {
		font-size: 30px;
	}
	h4 {
		font-size: 20px;
	}

	.text-container {
		margin: 2rem;
	}

	.custom-button {
		margin-top: 2rem;
	}
}

.save-exit-button-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	text-align: right;
}

.text-container {
	padding: 1rem;
}

.content-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1000px;
	margin: 2rem;
}

.input-container {
	display: flex;
	// margin: 1rem 0rem;
}

.input-container div {
	padding: 0rem 1rem 0rem 0rem;
	width: 100%;
}
.warning-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10rem;
}
p {
	color: #000;
	font-size: small;
}

.warning-text div {
	padding: 0rem 1rem;
	width: 100%;
}
.button-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	margin-bottom: 0rem;
}

.custom-label {
	padding-left: 1rem;
}

.custom-input {
	background-color: #f9f9f9;
	border: 3px solid rgba(255, 147, 0, 0.3);
	border-radius: 4px;
	padding: 1rem;
	margin: 1rem;
	outline: none;
	width: 100%;
	font-weight: 900;
}
.custom-input-error {
	border: 3px solid tomato;
	color: tomato;
}
.input-border-error {
	background-color: #f9f9f9;
	border: 3px solid tomato;
	border-radius: 4px;
	padding: 1rem;
	margin: 1rem;
	outline: none;
	width: 100%;
}

.custom-input:focus {
	border: 3px solid #ff9300;
	background-color: #ffffff;
}

.custom-input-error:focus {
	border: 3px solid tomato;
	color: tomato;
}

.save-exit-button {
	border: 3px solid black;
	color: black;
	font-weight: 900;
}

.custom-button {
	background-color: white;
	width: 200px;
	padding: 0.5rem;
	margin: 0.5rem 0rem;
	border-radius: 5px;
	border: none;
	font-weight: 900;
}

.custom-back-button {
	background-color: white;
	width: 200px;
	padding: 0.5rem;
	margin: 0.5rem 0rem;
	border-radius: 5px;
	border: solid 2px;
	font-weight: 900;
}

.custom-back-button:hover {
	background-color: $quadFi-orange;
	color: white;
	border-color: $quadFi-orange !important;
}

.enabled {
	background-color: #222222;
	color: white;
}

.enabled:hover {
	background-color: #020202;
	color: #fff;
}
.enabled:focus,
.enabled:active {
	background-color: #020202 !important;
	color: #fff !important;
	box-shadow: none;
	outline: 5px auto -webkit-focus-ring-color;
}

.disabled {
	background-color: #eeeeee;
	color: white;
	pointer-events: all;
}

button[disabled=""] {
	background-color: #eeeeee !important;
	color: white !important;
	pointer-events: none !important;
}

a.btn.disabled {
	background-color: #eeeeee;
	color: white;
	pointer-events: all;
}

.legal_documents .legal-documents {
	width: 100%;
}

.radios-container {
	display: flex;
	flex-direction: column;
	padding: 1rem;
}
.radios-container-horizontal {
	display: flex;
	flex-direction: row;
	padding: 1rem;
}

// Aplication Flow / Employment
.cards-container {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.icon-card {
	position: relative;
	margin: 0rem 0rem 0rem 1rem;
	input {
		position: absolute;
		z-index: -100000;
		left: -100000;
		top: -100000;
	}
}

.card-label {
	width: 200px;
	height: 200px;
	background-color: #f9f9f9;
	border: 3px solid rgba(255, 147, 0, 0.3);
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	> * {
		padding: 5px;
	}

	img {
		width: 90px;
		height: 90px;
		margin: 1rem;
	}
}

.custom-radio:checked ~ label {
	background-color: #ffffff;
	border: 3px solid #ff9300;
}

.purpose-row .custom-radio:checked ~ label {
	outline: 4px solid #ff9300;
	background-color: #ffffff;
	border: 3px solid #ff9300;
}

// Login
.base-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto auto;
	padding: 5rem 4rem;
	background: #fdfefe;
	box-shadow: 12px 8px 44px -12px rgba(27, 58, 146, 0.16);
	border-radius: 20px;

	button {
		background: olive;
		border-radius: 4px;
	}

	.header {
		padding: 1rem;
	}

	.content {
		margin: 1rem;
		width: 100%;
	}

	.cta-button {
		margin: 1rem;
	}

	.user-input {
		padding: 0.5rem;
		width: 100%;
		border: 1px solid #f2f2f2;
		box-sizing: border-box;
		border-radius: 4px;
	}

	.btn {
		font-weight: 900;
		background-color: #ff9300;
		color: white;
	}

	.btn:hover {
		background-color: #ffa931;
	}

	.footer {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
}

.trophy-icon {
	height: 200px;
	width: auto;
}

.location-search-container {
	padding: 0rem 1rem;
}

.autocomplete-dropdown-container {
	margin: 0rem 1rem 0rem 1rem;
}

.suggestion-item--active {
	background-color: #ff9300;
}

input[type="month"]::-webkit-inner-spin-button,
input[type="month"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
}

input[type="month"] {
	-moz-appearance: textfield;
}

.full-screen-loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(255, 255, 255, 0.6);
	z-index: 999999999999;
}

.full-screen-loader img {
	width: 120px;
}

.resendEmailLink {
	display: inline-block;
	background: #fd9c01;
	color: white;
	padding: 2px 5px;
	font-weight: 600;
	border-radius: 5px;
	margin-left: 3px;
	cursor: pointer;
}

.load-class {
	position: relative;
	width: 100px;
	height: 100px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;

	img {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		left: 0;
		right: 0;
	}
}

.product-sectionOuter.no-scroll {
	width: 100%;
}

.custom-upload-control {
	position: relative;
	overflow: hidden;
	cursor: pointer;
}

.custom-upload-control .file-control {
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	opacity: 0;
	z-index: 1;
	cursor: pointer;
}

.input-container-pr {
    width: 100%;
    display: flex;
	margin-bottom: 15px;
}

.input-container-pr > div {
    width: 50%;
}

.input-container-pr > div span + div {
    border: none;
}

@media only screen and (max-width: 700px) {
	.input-container {
		flex-direction: column;
	}

	.cards-container {
		flex-direction: column;
	}

	.card-label {
		width: 150px;
		height: 150px;
	}
}

.product-container {
	width: 100%;
	height: 100%;

	display: grid;
	background-color: white;
	border-radius: 4px;
	.btn {
		font-weight: 900;
		background-color: #ff9300;
		color: white;
	}
}

.purpose-row {
	justify-content: center;
}

.set-width {
	width: 300px;
	height: 100%;
}

.form-check {
	margin: 1rem;
}

.info-container {
	position: absolute;
	bottom: 50px;
	right: 50px;
	cursor: pointer;
}

.info-container-product {
	float: right;
	margin-top: -25px;
	margin-right: 5px;
	cursor: pointer;
}

.info-document {
	float: right;
	margin-top: -25px;
	margin-right: 0;
	cursor: pointer;
}

.bottom-right-info {
	margin: 0;
	position: absolute;
	bottom: 20px;
	right: 25px;
}
.bottom-left-info {
	margin: 0;
	position: absolute;
	bottom: 0px;
	left: 75px;
	margin-top: 10px;
}

.hoverable-container:hover .tool-tip {
	visibility: visible;
}

.bottom-right-info .tool-tip-product {
	bottom: 10px;
	position: absolute;
	right: 0;
}

.tool-tip-product {
	visibility: hidden;
	width: 120px;
	background-color: #393939;
	color: #fff;
	text-align: center;
	padding: 1rem;
	border-radius: 10px 10px 0 10px;

	width: 250px;
	bottom: 10%;
	position: absolute;
	z-index: 1;
}

.info-container-product:hover .tool-tip-product {
	visibility: visible;
}

.tool-tip {
	visibility: hidden;
	width: 120px;
	background-color: #393939;
	color: #fff;
	text-align: center;
	padding: 1rem;
	border-radius: 10px 10px 0 10px;

	width: 250px;
	bottom: 100%;
	right: 50%;
	margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
	position: absolute;
	z-index: 1;
}

.info-container:hover .tool-tip {
	visibility: visible;
}

.info-with-question {
	margin: 0px 0 0 10px;
	position: relative;
	top: -2px;
	float: none;
}

.info-with-question .tool-tip-product {
	top: 34px;
	bottom: initial;
	left: initial;
	right: 0px;
}

.icon-card-container {
	bottom: 7px;
	right: 7px;
}

.no-scroll .card-label {
	// width: 175px !important;
	// height: 130px !important;
}

.no-scroll .content-container .icon-card b {
	padding-bottom: 0;
}

.icon-card-container img {
	max-width: 17px;
}

#page-section .load-class {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	// width: 100% !important;
	// height: 100% !important;
}

.text-container .product-title,
.text-container-margin {
	margin: 0 0 30px 0;
}

// .load-class-inner {
//     position: fixed;
//     z-index: 1111111;
//     top: 0;
//     left: 0;
//     height: 100%;
//     width: 100%;
//     min-width: 100vw;
//     min-height: 100vh;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

.load-class-inner {
	position: fixed;
	z-index: 1111111;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	min-width: 100vw;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translate(-49%, -50%);
}

.load-class-absoulte-outter {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.load-class-absoulte {
    transform: unset;
}

.load-class-inner img {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

// #page-section .load-class {
//     position: static !important;
//     transform: unset !important;
//   }

.content-container .button-container,
.button-container-margin {
	padding-top: 0 !important;
	margin: 28px 0 0 0;
}

.disabled {
	background-color: #d7d7d7;
}

.text-container-margin h1 {
	margin-bottom: 0;
}

.error-page-head {
	display: flex;
	align-items: center;
	margin: 0 0 25px 0;
}

.error-page-head h3 {
	font-size: 36px !important;
	color: #000;
	margin: -15px 0 0 10px;
	text-align: center;
}

.no-messages-text {
	padding: 0 0 40px 30px;
	font-size: 21px;
	font-weight: 700;
}

.error-page-head h3 b {
	font-weight: 800 !important;
}

.error-page-head img {
	max-width: 105px;
}

.error-page h4 {
	color: #000;
}

.error-page-text-content {
	padding: 0 30px;
}

.offer-list-col li > img {
	max-width: 115px;
}

.offer-list-col {
	display: flex;
	align-items: center;
}

.offer-list-col {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #000;
	margin: 0 0 20px 0;
}

.offer-list-col h1 {
	font-size: 45px;
}

.offer-list-col h3 {
	font-size: 30px;
}

// NEW DASHBOARD STYLING

.the-content-container {
	// margin-top: 80px;
	// margin-left: 255px;

	p {
		color: black;
	}

	padding: 7rem;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
}
.create_new {
	display: inline-block;
	float: right;
	margin-top: -65px;
	button {
		padding: 10px 30px;
		border-radius: 30px;
		background: orange;
		border: 0 none;
		color: white;
		font-size: 16px;
		cursor: pointer;
	}
}
.user-info-app {
	margin-top: 2rem;
}
.user-info-container {
	display: inline-block;
	display: flex;
}

.ui-1 {
	flex: 1;
}

.ui-1-1 {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.ui-2 {
	display: flex;
	flex-direction: column;
}

.ui-2-2 {
	flex: 1;

	.cCard {
		height: 80%;

		.cMain {
			height: 100%;
		}
	}
}

.cCard {
	flex: 1;
	position: relative;
	margin: 1rem 3rem 1rem 0rem;
	.cta {
		text-align: center;
		padding: 0.5rem;
		color: black;
	}
}

.cHeader {
	display: inline-block;
	background-color: white;
	padding: 0.5rem 2rem 0;
	color: $quadFi-orange;
	border-radius: 8px 8px 0 0;
}

.cHeader-2 {
	padding: 1rem;
	background-color: #414141;
	border-radius: 8px 8px 0 0;
	color: white;
}

.cHeader-highlight {
	position: absolute;
	left: 68.14%;
	right: 9.72%;
	top: -4%;
	// bottom: 26.62%;

	background: #ff9300;
	border-radius: 0px 0px 8px 8px;
	text-align: center;
	padding: 0.5rem;
}

.cMain {
	background-color: white;
	padding: 2rem 3rem;
	text-align: center;
	color: black;
	border-radius: 0 8px 8px 8px;
}

.app-prog-container {
	width: 150px;
	height: 150px;
	// border: 16px solid #222222;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto 1rem;
}

.mini-statement-table {
	width: 100%;
	font-weight: 600;

	tr {
		border-bottom: 2px solid rgb(170, 170, 170);
	}
	td {
		padding: 1rem;
	}

	.due {
		color: #ff9300;
	}

	.paid {
		color: #4bcb8e;
	}

	.td-highlight {
		color: white;
		font-weight: 900;
		padding: 10px 0;
		background-color: #222222;
		border: 1px solid #ffffff;
		border-radius: 3px;
	}
}

.refer-text {
	background: #ffffff;
	border: 1px solid #ff9300;
	border-radius: 20.5px;
	width: 100%;
	padding: 1rem;
	margin: 1rem;
	resize: none;
	text-align: center;
}

/*File Upload*/
.alert_section {
	position: fixed;
	top: 110px;
	right: 15px;
	z-index: 1;
	div {
		color: white;
		font-size: 14px;
		font-weight: 800;
		display: inline-block;
		min-width: 530px;
		border-radius: 10px;
		padding: 20px 22px;
	}
	.alert-success {
		background: #12c99b;
	}
	.alert-danger {
		color: red;
	}
}
.file-card {
	display: inline-block;
	padding: 2rem;
	background: #fdfefe;
	box-shadow: 12px 8px 44px -12px rgba(27, 58, 146, 0.16);
	border-radius: 20px;
	text-align: center;
	position: relative;
	width: 44%;
	margin: 3%;

	.react_upload {
		height: 100%;
		width: 100%;
		div {
			display: inline-block;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.file-status {
		color: white;
		background: #09b66d;
		border-radius: 25px;
		font-size: 12px;
		padding: 7px 20px;
		margin-bottom: 13px;
		margin-top: 0px;
	}

	.file-status-rejected {
		background: red !important;
	}

	.file-status-pending {
		background: $quadFi-orange !important;
	}

	.main {
		padding: 60px 30px;
		background: #fffbf4;
		/*border: 1px dashed #dae1ed;*/
		border-radius: 4px;
		img {
			width: 85px;
		}
		img.large_img {
			width: 100px;
			margin-top: -20px;
		}
		h4 {
			margin: 1rem 0;
			b {
				display: inline-block;
				min-height: 20px;
				h1 {
					font-size: 18px;
					font-weight: 600;
					margin: 0;
				}
			}
		}
		h5 {
			margin: 1rem 0;
			color: black;
			b {
				color: #ff9e00;
			}
		}
	}
}

// Medium Screens (iPad)
@media only screen and (max-width: 700px) {
	.base-container {
		padding: 3rem 2rem;
	}

	.content-container {
		// text-align: center;
		margin: 2rem 0rem;
	}

	.input-container {
		flex-direction: column;
	}

	.cards-container {
		flex-direction: column;
	}

	.card-label {
		img {
			width: 90px;
			height: 90px;
			margin: 1rem;
		}
	}
}

// Small Screens (Mobile)
@media only screen and (max-width: 500px) {
	section {
		zoom: 0.6;
		-moz-transform: scale(0.6);
	}

	.custom-input {
		margin: 0.5rem 0rem;
	}

	.readable {
		text-align: left;
		line-height: 1.5rem;
		margin-top: 1rem;
	}

	.form-check {
		zoom: 1.6;
		-moz-transform: scale(1.6);
	}
}

/*=========new css here ======*/

.the-content-container.nw-costumer {
	padding: 7rem 60px;
	width: 100%;
	height: auto;
}

.nwPding {
	padding-right: 100px;
}
.cCard {
	margin: 0;
}
.cMain {
	padding: 2rem 27px;
}
.cmn-padding {
	padding-top: 0;
}
.n-mini-statement tr td {
	font-weight: 500;
	font-size: 12px;
	font-family: "Poppins", sans-serif;
}
.n-credit .cHeader-highlight {
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
}

.n-referal {
	font-size: 7.5px;
	line-height: 15px;
	font-weight: 400;
	color: #131313;
	width: 100%;
	padding: 9px 6px;
	border: 1px solid #ff9300;
	border-radius: 21px;
	&:focus-visible {
		outline: none;
	}
}
.btn-copylnik {
	position: absolute;
	right: 27px;
	font-size: 9px;
	line-height: 25px;
	color: #fff;
	font-weight: 600;
	padding: 4px 6px;
	border-radius: 0;
	border-top-right-radius: 21px;
	border-bottom-right-radius: 21px;
	background-color: #ff9300;
}
.n-mini-statement tr th:first-child {
	text-align: left;
}
.n-mini-statement tr th:nth-child(2) {
	text-align: right;
}
.n-mini-statement tr th {
	font-size: 14px;
	color: #838383;
}
.n-mini-statement tr td:first-child {
	padding-left: 0;
	text-align: left;
	font-size: 12px;
	font-family: "Poppins", sans-serif;
	text-transform: uppercase;
}
.n-mini-statement tr td:nth-child(2) {
	padding-right: 0;
	text-align: right;
	font-weight: 500;
	font-size: 12px;
	font-family: "Poppins", sans-serif;
}
.n-mini-statement tr:first-child {
	border-bottom: 1px solid #8291a9;
}
.n-mini-statement tr {
	border-bottom: 1px solid #f2f2f2;
}
.q-share {
	font-size: 10px;
	font-weight: 600;
	display: block;
	text-align: left;
	margin-bottom: 9px;
	color: #222222;
}
.pdno-36 {
	padding-top: 53px;
}
.user-name-container h1 {
	font-size: 27px;
	font-weight: bold;
	color: #727272;
	letter-spacing: 0.8px;
	padding-top: 45px;
}
.user-name-container h4 {
	font-size: 18px;
	font-weight: 600;
	color: #8a8787;
}
.sumry-title p {
	margin: 0;
	font-weight: 500;
	line-height: 15px;
	color: #000;
}
.app-prog-container {
	margin: 15px auto 1rem;
}
.user-info-app h3 {
	font-size: 20px;
	color: #555555;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0.16875px;
	margin-bottom: 30px;
}
.mrbtm {
	margin-bottom: 8px;
}
.n-mini-statement td {
	padding: 18px 0 20px 0;
}

.CircularProgressbar {
	.CircularProgressbar-path {
		stroke: #222222;
		stroke-linecap: butt;
	}
	.CircularProgressbar-trail {
		stroke: #fa9430;
		stroke-linecap: butt;
	}
	.CircularProgressbar-text {
		fill: #000;
		font-size: 25px;
		dominant-baseline: middle;
		text-anchor: middle;
		font-weight: 700;
	}
}
.title_pst {
	padding-top: 35px;
}
.title_pst a {
	font-size: 12px;
	font-weight: 600;
	color: #2a3135;
	text-decoration: none;
	text-align: left;
	display: block;
}
.authorName {
	font-size: 10px;
	font-weight: 600;
	color: #858585;
	padding: 5px 0;
}
.txtalgn {
	text-align: left;
}
.content_pst {
	font-size: 12px;
	color: #5a5a5a;
}
.pdno-36 img {
	width: 100%;
}
.linkFont {
	font-size: 11px;
	font-weight: 700;
	text-decoration: none;
	color: #222222 !important;
	cursor: pointer;
}
.wdwth {
	width: 9px !important;
}
.persnlLoan {
	text-align: left;
	font-weight: 800;
	font-size: 15px;
	line-height: 24px;
}
.textAlig {
	text-align: left;
}
.textAlig span,
.textAlig small {
	display: block;
	color: #8d8d8d;
	font-size: 8px;
	font-weight: 400;
}
.cmn-padding .mini-statement-table {
	td {
		.td-highlight {
			padding: 0;
			border-radius: 3px;
			font-size: 11px;
			width: 81px;
			height: 31px;
			line-height: 31px;
			text-align: center;
		}
		&:first-child {
			padding-left: 0;
		}
		text-align: left;
		padding-left: 0;
		.monthl {
			display: block;
			font-size: 10px;
			font-weight: 600;
		}
	}
	th {
		width: 120px;
		text-align: left;
		font-size: 11px;
		color: #8d8d8d;
		line-height: 24px;
		letter-spacing: 0.06875px;
		color: #8d8d8d;
	}
	tr {
		&:first-child {
			border-bottom: 0.93px solid #8291a9;
		}
		border-bottom: none;
	}
}

.tenfull {
	max-width: calc(100% - 72px);
}
.cmplt-btn {
	background-color: #ff9300;
	font-size: 9px;
	border-radius: 4px;
	color: #fff;
	font-weight: 600;
}

/*====bank Page ====*/

.amtitle {
	display: block;
	font-size: 11px;
	color: #777;
	font-weight: 600;
}
.amountcrd {
	font-weight: 800;
	font-size: 20px;
	color: #222222;
}
.payment-history {
	display: flex;
	justify-content: center;
	width: 385px;
	.slsh {
		align-self: flex-end;
		font-weight: 400;
		font-size: 20px;
		color: #060405;
	}
}
.auto-btn {
	border: 0.933333px solid #8291a9;
	border-radius: 5.6px;
}
.transion-outer {
	width: 100%;
	.titlname {
		font-weight: 600;
		font-size: 13px;
		line-height: 20px;
		text-transform: capitalize;
		color: #838383;
		padding-bottom: 5px;
	}
	.bank-select {
		height: 26px;
		width: 82px;
		border-radius: 6px;
		font-family: Poppins;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 19px;
		border: 0.933333px solid #8291a9;
		background-color: transparent;
		color: #545454;
		text-align: center;
		text-transform: uppercase;
		option {
			text-align: center;
		}
	}
}
ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.transitionOuter {
	list-style: none;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	background-color: #fff;
	li {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: 28px 0;
		div {
			width: calc(100% / 3);
			&:last-child {
				text-align: right;
			}
			&:nth-child(2) {
				text-align: right;
			}
			span {
				display: block;
				margin: 0 auto;
				font-size: 12px;
				font-weight: 700;
				text-transform: uppercase;
				text-align: center;
			}
		}
		&:first-child {
			div {
				&:last-child {
					span {
						&:first-child {
							width: 58px;
							height: 28px;
							background: #09b66d;
							border-radius: 14px;
							text-align: right;
							line-height: 28px;
							color: #fff;
						}
					}
				}
				&:first-child {
					font-family: Poppins;
					font-size: 13px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px;
					letter-spacing: 0px;
					text-align: left;
					color: #000000;
					text-transform: uppercase;
				}
				&:nth-child(2) {
					font-family: Poppins;
					font-size: 13px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px;
					letter-spacing: 0px;
					text-align: right;
					color: #000000;
					text-transform: uppercase;
				}
			}
		}
		&:nth-child(2) {
			div {
				&:last-child {
					span {
						width: 84px;
						height: 28px;
						line-height: 28px;
						border: 0.933333px solid #ff8a48;
						border-radius: 14px;
						color: #ff8a48;
					}
				}
				&:first-child {
					font-family: Poppins;
					font-size: 13px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px;
					letter-spacing: 0px;
					text-align: left;
					color: #000000;
					text-transform: uppercase;
				}
				&:nth-child(2) {
					font-family: Poppins;
					font-size: 13px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px;
					letter-spacing: 0px;
					text-align: right;
					color: #000000;
					text-transform: uppercase;
				}
			}
		}
		&:last-child {
			text-align: center;
			div {
				&:last-child {
					span {
						width: 81px;
						height: 28px;
						line-height: 28px;
						border: 0.933333px solid #0081ff;
						border-radius: 14px;
						color: #0081ff;
					}
				}
				&:first-child {
					font-family: Poppins;
					font-size: 13px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px;
					letter-spacing: 0px;
					text-align: left;
					color: #000000;
					text-transform: uppercase;
				}
				&:nth-child(2) {
					font-family: Poppins;
					font-size: 13px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px;
					letter-spacing: 0px;
					text-align: right;
					color: #000000;
					text-transform: uppercase;
				}
			}
		}
	}
}
.brder-mnul {
	border-bottom: 1px solid #c3c3c3;
}
#page-section.bnk-section {
	background-color: #f2f2f2 !important;
	align-items: flex-start !important;
}
.verticalDots {
	display: inline-block;
	position: absolute;
	right: 20px;
	top: 2px;
}
.bankwarp {
	padding-bottom: 124px;
	margin-top: 16px;
}
.page-title {
	font-size: 27px;
	font-style: normal;
	font-weight: 800;
	line-height: 33px;
	letter-spacing: 0.16875000298023224px;
	text-align: left;
	color: #727272;
	margin-bottom: 0;
	margin-top: 0;
}
.content-title {
	font-size: 22px;
	font-weight: 700;
	line-height: 27px;
	letter-spacing: 0.16875000298023224px;
	text-align: left;
	color: #555555;
	margin: 35px 0 35px 0;
}

._2D_VfCL6PDramYdGNPlSSA {
	margin: 0 auto !important;
}

.new-wrpsbnk {
	display: flex;
}

.card-details {
	width: 300px;
	margin: 45px auto 0 auto;
	li {
		width: 100%;
		// height:56px;
		// line-height: 56px;
		padding: 12px 0;
		border-radius: 8px;
		background: #ffffff;
		margin-bottom: 12px;
		display: flex;
		justify-content: space-around;
		position: relative;
		border: 1px solid #ff9300;
		span {
			align-self: center;
			font-size: 12px;
			font-weight: 400;
			color: #060405;
			&:nth-child(2) {
				font-size: 16px;
				padding-top: 10px;
			}
		}
	}
}

.addButton {
	padding: 37px 0 41px 0 !important;
	.add-new-cardb {
		width: 300px;
		height: 56px;
		line-height: 56px;
		border: 1px solid #ff9700;
		border-radius: 8px;
		text-align: center;
		font-family: Roboto;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 14px;
		letter-spacing: 0em;
		color: #060405;
		font-family: "Roboto", sans-serif;
	}
}

.edit-delete {
	position: absolute;
	cursor: pointer;
	width: 110px !important;
	background: #fff;
	border-radius: 5px;
	top: 0;
	right: -130px;
	padding: 19px 12px 10px 12px !important;
	border-radius: 8px;
	box-shadow: 0px 1px 2px #0000000f;
	div {
		padding: 0 !important;
	}
	span {
		display: block;
		padding-bottom: 5px;
	}
	img {
		width: 18px;
		margin-bottom: 5px;
		margin-right: 5px;
	}
	&:after {
		position: absolute;
		left: 0;
		content: "";
		width: 0;
		height: 0;
		border-top: 8px solid transparent;
		border-right: 16px solid #fff;
		border-bottom: 8px solid transparent;
		top: 15px;
		left: -13px;
	}
}

span.edit {
	font-size: 12px !important;
	font-weight: 400;
	color: #000;
}
span.delete {
	font-size: 12px !important;
	font-weight: 400;
	color: #000;
}

.payNow {
	height: 33px;
	width: 234px;
	background: #ff9300;
	border-radius: 9px;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0px;
	text-align: center;
	color: #fff;
	margin-top: 24px;
}
.mn-pdstop {
	padding-top: 47px;
}

// ._2D_VfCL6PDramYdGNPlSSA  {
//     background: linear-gradient(32.2deg, #183671 -3.73%, #404040 106.54%) !important;

//   }

.progress-section {
	padding-bottom: 30px;
	.progress-bar {
		background-color: #fa9430;
	}
	.progress {
		height: 5px;
	}
}
.pro-title {
	font-family: Montserrat;
	font-size: 16px !important;
	font-style: normal;
	font-weight: 800 !important;
	line-height: 24px !important;
	margin-bottom: 5px !important;
	text-align: left;
	color: #222222 !important;
	span {
		font-size: 12px !important;
		font-style: normal;
		font-weight: 600 !important;
		margin-right: 5px !important;
		display: inline-block;
	}
}

.appstatus {
	text-align: left;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	span {
		color: #222;
		&:first-child {
			display: inline-block;
			font-size: 10px !important;
			font-weight: 600 !important;
			margin-right: 5px;
		}
		&:last-child {
			display: inline-block;
			font-size: 8px !important;
			font-weight: 600 !important;
			color: #ff9300;
		}
	}
	.completeapp {
		height: 24px;
		width: 144px;
		border-radius: 3px;
		border: 1px solid #ffffff;
		background: #222222;
		font-size: 9px;
		font-style: normal;
		font-weight: 900;
		line-height: 12px;
		letter-spacing: 0px;
		text-align: center;
		color: #ffffff;
		padding: 0;
	}
}

.nwsmry {
	max-width: 390px;
	margin-left: 131px;
	margin-top: 95px;
	.dateOuter {
		display: flex;
		justify-content: space-between;
		border-bottom: none;
		font-weight: 500;
		td {
			font-weight: 600;
			font-size: 13px;
		}
	}
}

.transitionOuter {
	li {
		&:last-child {
			padding-bottom: 5px;
			border-bottom: 1px solid #dedede;
		}
	}
}
.nwsmry {
	.cMain {
		padding: 10px 27px 10px 27px;
	}
}

.slsh {
	width: 50px;
}
.due-outer,
.money-save {
	width: 250px;
}
.slsh,
.due-outer,
.money-save {
	text-align: center;
}
.addition-title {
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	line-height: 27px;
	letter-spacing: 0.16875000298023224px;
	text-align: left;
	padding-left: 0 !important;
	color: #555555 !important;
}
.addi-wrp {
	text-align: center;
	margin-top: 40px;
	padding-right: 100px !important;
	.addinput {
		width: 170px;
		padding: 0 !important;
		margin: 0 auto;
		height: 28px;
		.form-control {
			padding: 0 15px;
			line-height: 28px;
			height: 100%;
		}
	}
}
.autopay {
	text-align: center;
	margin-top: 24px;
	margin-bottom: 112px;
	span {
		font-family: Poppins;
		font-size: 13px;
		font-weight: 500;
		line-height: 19px;
		text-align: left;
		margin-right: 25px;
	}
}
.bgbtn {
	position: relative;
	height: 26px;
	width: 42px;
	border-radius: 16px;
	// opacity: 25%;
	background: #00d849;
	border: 4px solid #464646;
	&:after {
		position: absolute;
		content: "";
		width: 18px;
		height: 18px;
		border-radius: 50%;
		background: #fff;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		box-shadow: 0px 8px 22px #0000004a;
		transition: 0.3s linear;
	}
}

.bgbtn.activeAft::after {
	left: auto;
	right: 0;
}

.bgbtn.activeAft {
	background-color: green;
}
.bgbtn.notActiveAft {
	background-color: red;
}

.tltip {
	margin-left: 21px;
	display: inline-block;
	opacity: 0.6;
	cursor: pointer;
}
.tooltipBox {
	width: 176px;
	.tootip-title {
		display: flex;
		margin-bottom: 10px;
		h6 {
			margin-bottom: 0;
			font-size: 10px;
			font-weight: 700;
			line-height: 18px;
			color: #585858;
		}
		img {
			margin-right: 5px;
		}
	}
	.payment-content {
		li {
			font-size: 10px;
			font-weight: 500;
			line-height: 12px;
			color: #585858;
			margin-bottom: 8px;
		}
	}
}

.autoPlaysecton {
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-wrap: wrap;
	.credit-cards-container {
		width: 50%;
	}
	.tootflexbox {
		width: 50%;
		padding-left: 45px;
	}
}

.bank-title {
	padding-left: 30px;
}

#page-section {
	align-items: center;
}

#page-section.bnk-section {
	align-items: flex-start;
}
.error-container {
	width: 100%;
}
.error-container p {
	margin: 0;
}
.bnk-section.wrapper {
	padding: 5rem;
}

.input-container {
	width: 100%;
}

#page-section.bnk-section .content-container {
	align-items: flex-start;
}
.overflow {
	overflow-y: hidden;
}
.overflow section {
	min-height: 100vh !important;
}
.gnrl-documents section {
	min-height: auto !important;
	height: auto !important;
}

/*************  Legal Documnets   *****************/
.legalDoc_main {
	.main {
		button {
			padding: 10px 45px;
			border: 0 none;
			background: #ff9e00;
			color: white;
			font-size: 16px;
			border-radius: 30px;
			margin-top: 30px;
		}
		a {
			display: inline-block;
			width: 100%;
			margin-top: 15px;
			color: grey;
			text-decoration: underline;
		}
	}
}
.legal_doc_modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.2);
	color: black;
	z-index: 9999;
	.modal-content {
		padding: 30px;
	}
	.modal-title {
		span {
			position: absolute;
			top: 13px;
			right: 35px;
			cursor: pointer;
		}
	}

	.modal.show .modal-dialog {
		transform: translate(-50%, -50%);
		left: 50%;
		top: 50%;
		position: absolute;
		width: 100%;
		max-width: 800px;
		text-align: left;

		.modal-footer {
			text-align: center;
			display: inline-block;
			width: 100%;
			div {
				display: inline-block;
				width: 100%;
				text-align: center;
			}
			button {
				display: inline-block;
				min-width: 230px;
				padding: 15px;
				background: black;
				color: white;
				font-size: 18px;
				border: 0 none;
				margin: 30px 0;
				border-radius: 5px;
			}
		}
		header.modal-header,
		.modal-footer {
			border-bottom: 0 none;
			border-top: 0 none;
		}
		.modal-body {
			max-height: 500px;
			overflow: auto;
			padding: 20px;
			background: #eeeeee85;
			border: 1px solid #ccc;
			border-radius: 5px;
			margin: 0 70px;
			p {
				font-size: 17px;
				line-height: 24px;
				color: black;
			}
		}
		/* Customize the label (the container) */
		.container {
			display: block;
			position: relative;
			padding-left: 35px;
			margin-bottom: 12px;
			cursor: pointer;
			font-size: 18px;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}

		/* Hide the browser's default checkbox */
		.container input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
		}

		/* Create a custom checkbox */
		.checkmark {
			position: absolute;
			top: 0;
			left: 110px;
			height: 25px;
			width: 25px;
			background-color: #eee;
		}

		/* On mouse-over, add a grey background color */
		.container:hover input ~ .checkmark {
			background-color: #ccc;
		}

		/* When the checkbox is checked, add a blue background */
		.container input:checked ~ .checkmark {
			background-color: #2196f3;
		}

		/* Create the checkmark/indicator (hidden when not checked) */
		.checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}

		/* Show the checkmark when checked */
		.container input:checked ~ .checkmark:after {
			display: block;
		}

		/* Style the checkmark/indicator */
		.container .checkmark:after {
			left: 9px;
			top: 4px;
			width: 7px;
			height: 14px;
			border: solid white;
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
}

/*========= message css =========*/

.messaeOuter {
	display: flex;
	.name-title {
		font-family: Montserrat;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 17px;
		letter-spacing: 0px;
		text-align: left;
		color: #222222;
		padding: 2px 0 7px;
	}
	.emailid {
		font-family: Montserrat;
		font-size: 11px;
		font-style: normal;
		font-weight: 400;
		line-height: 13px;
		letter-spacing: 0.18333333730697632px;
		text-align: left;
		color: #222222;
	}
	.msg-content {
		font-family: Montserrat;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 19px;
		letter-spacing: 0px;
		text-align: left;
		width: 285px;
		display: inline-block;
		color: #222222 !important;
	}
	.timemes {
		font-family: Montserrat;
		font-size: 11px;
		font-style: normal;
		font-weight: 400;
		line-height: 15px;
		letter-spacing: 0.20000000298023224px;
		text-align: right;
	}
	.avtar-msg {
		display: inline-block;
		width: 60px;
		height: 60px;
		img {
			width: 100%;
		}
	}
	span.timemes {
		img {
			margin-right: 7px;
		}
	}
}

ul.message-wrapper {
	max-width: 440px;
	li {
		padding: 15px 0 15px 0;
		border-bottom: 1px solid #e1e6ed;
		&:first-child span.timemes {
			position: relative;
			display: flex;
			&:after {
				position: absolute;
				content: "";
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background-color: #fa9430;
				left: 0;
				right: 0;
				margin: 0 auto;
				top: 25px;
			}
		}
		&:nth-child(2) span.timemes {
			position: relative;
			&:after {
				position: absolute;
				content: "";
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background-color: #fa9430;
				left: 0;
				right: 0;
				margin: 0 auto;
				top: 25px;
			}
		}
	}
}

span.timemes {
	display: flex;
}

.msg-spacing {
	padding: 6rem 5rem;
	display: flex;
}
.emailinfo {
	display: flex;
	padding-bottom: 20px;
	span {
		display: block;
	}
	.avtar-msg {
		width: 60px;
		height: 60px;
		img {
			width: 100%;
		}
	}
	.emailid {
		align-self: center;
	}
}
.message-expand {
	padding-left: 50px;
	background: transparent;
	padding-right: 50px;
	.name-title {
		font-family: Montserrat;
		font-size: 30px;
		font-style: normal;
		font-weight: 400;
		line-height: 37px;
		letter-spacing: 0px;
		text-align: left;
		padding: 15px 0;
	}
	.msg-content {
		p,
		span,
		small {
			font-family: Montserrat;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: 0px;
			text-align: left;
			color: #686871;
			margin-bottom: 0;
		}
	}
	small.expnd-name {
		display: block;
	}
}
.chat-btn {
	padding: 47px 0 0 0;
	textarea {
		width: 100%;
		background: #ffffff;
		border: 1px solid #ff9700;
		border-radius: 11px;
		resize: none;
		height: 107px;
		font-family: Montserrat;
		font-size: 14px;
		font-style: normal;
		font-weight: 300;
		line-height: 26px;
		letter-spacing: 0.20000000298023224px;
		text-align: left;
		color: #505172;
		padding: 5px 0 8px 20px;
		opacity: 0.5;
	}
}

.chat-cont {
	border-bottom: 1px solid #e1e6ed;
	// padding-bottom: 60px;
}
.bgcmbtn {
	box-shadow: 3px 3px 10px -1px rgba(11, 27, 102, 0.304824);
	border-radius: 20px;
	color: #8f9bb2;
	font-family: Montserrat;
	font-size: 12px;
	font-style: normal;
	font-weight: 800;
	line-height: 16px;
	letter-spacing: 0px;
	text-align: center;
}

.bgcmbtn.send {
	background: #ff9300;
	color: #fff;
	margin-right: 15px;
}
.send-btn {
	display: flex;
	justify-content: space-between;
}

.mess-navs {
	li {
		width: 100%;
	}
}
.mess-navs li a.active {
	border: none;
	background-color: #fff;
	opacity: 1;
}
.nav-tabs.mess-navs .nav-link {
	color: #000 !important;
}
.nav-tabs.mess-navs .nav-link:hover,
.nav-tabs .nav-link:focus {
	border-color: transparent;
}

.nav-tabs.mess-navs .nav-link {
	opacity: 0.7;
}
.table-wrp thead {
	background-color: #414141;
}
.table-wrp thead th {
	font-family: Montserrat;
	font-size: 11px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.06875000149011612px;
	color: #fff;
}

.tbl-space {
	padding: 5rem;
	.title {
		font-family: Montserrat;
		font-size: 27px;
		font-style: normal;
		font-weight: 800;
		line-height: 33px;
		letter-spacing: 0.16875000298023224px;
		text-align: left;
		color: #727272;
		margin-bottom: 30px;
		margin-top: 30px;
	}
}

.table-responsive table.table.table-hover {
	background-color: #fff;
}

.table-responsive table.table.table-hover thead {
	background: #414141;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
}

.table-responsive table.table.table-hover thead tr th {
	color: #fff;
	font-family: Montserrat;
	font-size: 11px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.06875000149011612px;
	text-align: center;
}

.table-responsive table.table.table-hover {
	tr {
		td {
			&:first-child {
				font-family: Montserrat;
				font-size: 15px;
				font-style: normal;
				font-weight: 900;
				line-height: 16px;
				letter-spacing: 0.08749999850988388px;
				text-align: center;
				color: #222;
			}
			font-family: Montserrat;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 21px;
			letter-spacing: 0.08749999850988388px;
			text-align: center;
			color: #222;
			border-top: none;
		}
	}
}

.table-responsive table.table.table-hover .badge-warning {
	height: 20px;
	width: 45px;
	border-radius: 14px;
	font-family: Poppins;
	font-size: 8px;
	font-style: normal;
	font-weight: 700;
	line-height: 17px;
	letter-spacing: 0px;
	text-align: center;
	text-transform: uppercase;
	background: #ff9301;
	color: #fff;
}
.table-responsive table.table.table-hover .badge-primary {
	height: 20px;
	width: 45px;
	border-radius: 14px;
	font-family: Poppins;
	font-size: 8px;
	font-style: normal;
	font-weight: 700;
	line-height: 17px;
	letter-spacing: 0px;
	text-align: center;
	text-transform: uppercase;
	background: #09b66d;
}

.sche-bg {
	background-color: #f2f2f2;
}
.table-responsive table.table.table-hover {
	.pagination {
		width: 100%;
		justify-content: center !important;
		margin-top: 20px;
	}
}

nav ul.pagination.justify-content-start {
	width: 100%;
	justify-content: center !important;
	li {
		&:first-child {
			display: none;
		}
		&:last-child {
			display: none;
		}
		.page-link {
			border: none;
			background-color: transparent;
			font-size: 18px;
			font-weight: 900;
			color: #000;
		}
	}
}
.msg-bgs {
	background-color: #fff !important;
}

.page-item.active .page-link {
	background-color: transparent;
	color: #000;
}

/*===offer page css======*/

.trophy-image {
	width: 180px;
	position: absolute;
	left: -40px;
	top: 0;
	z-index: 1;
}

.trophy-image img {
	width: 100%;
}
.ofr-spcing {
	padding: 0 0 45px 150px;
	h1 {
		font-size: 27px;
	}
	h3 {
		font-size: 18px;
	}
}

/*=======16/06/2021============*/

.sideBar-outer {
	width: 256px;
}
.csp-content-outer {
	width: calc(100% - 256px);
}
.nw-costumer {
	.container {
		max-width: 100%;
	}
	.noCurrent {
		padding-left: 15px;
	}
}

.bankInfo-outer {
	#page-section {
		flex: none;
		display: block;
		flex-direction: unset;
		padding: 7rem 60px;
	}
	.content-container {
		flex: unset;
		display: block;
		flex-direction: unset;
		justify-content: unset;
		max-width: 100%;
		margin: 0;
	}
	.bank-card-wrapper {
		width: 48%;
	}
	.nwsmry {
		max-width: 48%;
		width: 48%;
	}
	._2D_VfCL6PDramYdGNPlSSA {
		margin: 0 !important;
		width: 100%;
		min-width: 100%;
	}
	.autoPlaysecton {
		.tootflexbox {
			padding-left: 110px;
		}
	}
	.bank-title {
		padding-left: 0;
	}
}

.message-mainouter {
	background-color: #fff;
	.msg-spacing {
		padding: 6rem 20px;
	}
}

/*=========== personal information =======*/

.PI_page {
	.custom-select {
		width: 200px;
	}
}

/*================ 17062021 ================*/
/*==========product section==========*/

.product-wrappers {
	.product-sectionOuter {
		.purpose-row {
			display: flex;
			flex-wrap: wrap;
			.icon-card {
				width: calc(80% / 2 - 10%);
				margin: 0 0 20px 20px;
				&:first-child {
					margin-left: 0;
				}
				&:nth-child(3) {
					margin-left: 0;
				}
				.card-label {
					width: 100%;
					border: none;
					margin-bottom: 0;
					box-shadow: 0px 0px 3px #00000047;
					.inboxtitle {
						font-family: Montserrat;
						font-size: 24px;
						font-style: normal;
						font-weight: 700;
						line-height: 46px;
						letter-spacing: 0px;
						text-align: center;
						color: #0b162b;
					}
				}
			}
		}
		.product-title {
			font-family: Montserrat;
			font-size: 30px;
			font-style: normal;
			font-weight: 700;
			line-height: 60px;
			letter-spacing: 0px;
			text-align: center;
			color: #222222;
		}
	}
}

.purposeWrappers {
	.purpose-outer {
		.purpose-row {
			display: flex;
			flex-wrap: wrap;
			.icon-card {
				width: calc(70% / 2 - 2%);
				margin: 0 0 27px 40px;
				&:first-child {
					margin-left: 0;
				}
				&:nth-child(3) {
					margin-left: 0;
				}
				&:nth-child(5) {
					margin-left: 0;
				}
				.card-label {
					width: 100%;
					border: none;
					margin-bottom: 0;
					box-shadow: 0px 1px 4px #00000047;
					background: linear-gradient(
							180deg,
							#ffffff 0%,
							rgba(255, 255, 255, 0) 100%
						),
						linear-gradient(0deg, #f5f5f6, #f5f5f6);
					.inboxtitle {
						font-family: Montserrat;
						font-size: 18px;
						font-style: normal;
						font-weight: 700;
						line-height: 46px;
						letter-spacing: 0px;
						text-align: center;
						color: #0b162b;
					}
				}
			}
		}
		.product-title {
			font-family: Montserrat;
			font-size: 30px;
			font-style: normal;
			font-weight: 700;
			line-height: 60px;
			letter-spacing: 0px;
			text-align: center;
			color: #222222;
		}
	}
}

/*=========== hardcheck page==========*/

.hardcheckOuter {
	.scrollable {
		height: 360px;
		h4.readable {
			font-size: 16px;
			line-height: 35px;
		}
	}
}

.employedContains {
	.manual-rwx {
		display: flex;
		width: 106%;
	}
}

.purposeWrappers
	.purpose-outer
	.purpose-row
	.icon-card
	input.custom-radio:checked
	~ label.card-label {
	background: #f5f5f6;
}

.csp-content-outer {
	.nw-costumer {
		.cMain {
			position: relative;
			.float-right.btn {
				position: absolute;
				right: 0;
				top: 0;
			}
		}
		.progress-section {
			margin-bottom: 15px;
		}
	}
}

.tbl-space.noappfound {
	padding: 7rem 95px;
	height: 100vh;
	padding-right: 30px;
	.noapp-found {
		display: flex;
		align-items: center;
		height: 100%;
		justify-content: center;
	}
}

.tbl-space.noappfound .noapp-found p {
	font-size: 20px;
}

.amount-pointer {
	pointer-events: none;
}

.nw-inputcontainer {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 30px;
	.custom-label {
		padding-left: 0;
	}
	.degreeOuter {
		width: 48%;
		select {
			margin: 0;
		}
		margin-right: 20px;
		input {
			margin: 0;
		}
	}
	.schoolouter {
		width: 48%;
		select {
			margin: 0;
		}
		.reselects {
			.css-yk16xz-control {
				padding: 8px;
				background-color: #f9f9f9;
				border-color: rgba(255, 147, 0, 0.3);
				border-width: 3px;
				font-weight: 900;
			}
			.css-1pahdxg-control {
				padding: 8px !important;
				background-color: #f9f9f9 !important;
				border-color: rgba(255, 147, 0, 0.3) !important;
				border-width: 3px !important;
				font-weight: 900;
				box-shadow: none !important;
				&:hover {
					padding: 8px;
					background-color: #f9f9f9;
					border-color: #ff9300 !important;
					border-width: 3px !important;
				}
			}
		}
	}
}

.portalOuter {
	.user-name-container {
		h1 {
			padding-top: 0;
		}
	}
}

.bars {
	display: none;
}

.emailid {
	span {
		color: #25252b;
		font-size: 14px;
	}
}
span.timemes {
	font-family: Montserrat;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0px;
	text-align: left;
	color: #686871;
}

.message-expand .name-title {
	color: #25252b;
}

.eduction-new .input-container div {
	width: auto;
	padding-right: 0 !important;
}
.eduction-new .input-container {
	.edubox {
		width: 50%;
	}
	justify-content: space-between;
	input {
		margin: 1rem 0;
	}
}
.css-1pahdxg-control:hover,
.css-1pahdxg-control:focus {
	border-color: transparent !important;
	border: none !important;
	box-shadow: none !important;
	outline: none;
}

.eduction-new {
	.css-b8ldur-Input {
		margin: 0px !important;
	}
	span.css-1okebmr-indicatorSeparator {
		display: none !important;
		width: 0 !important;
	}
	.css-1pahdxg-control:hover,
	.css-1pahdxg-control:focus {
		border-color: transparent !important;
		border: none !important;
		box-shadow: none !important;
		outline: none;
	}
	#nf-degree {
		width: 100%;
		padding-right: 0;
		flex-wrap: nowrap;
	}
	.css-yk16xz-control {
		&:focus {
			border: 3px solid #ff9300;
			background-color: #ffffff;
		}
		padding-right: 0;
		padding: 0 1rem;
		display: flex;
		/* -webkit-align-items: center; */
		/* -webkit-box-align: center; */
		-ms-flex-align: center;
		/* align-items: center; */
		background-color: #f9f9f9;
		border-color: transparent;
		border-radius: 4px;
		border-style: none;
		border-width: 0;
		cursor: default;
		border: none;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		/* display: flex; */
		-webkit-box-flex-wrap: wrap;
		/* -webkit-flex-wrap: wrap; */
		-ms-flex-wrap: wrap;
		/* flex-wrap: wrap; */
		/* -webkit-box-pack: justify; */
		/* -webkit-justify-content: space-between; */
		/* justify-content: space-between; */
		/* min-height: 38px; */
		/* outline: 0!important; */
		/* position: relative; */
		/* -webkit-transition: all 100ms; */
		/* transition: all 100ms; */
		/* box-sizing: border-box; */
	}
}

.highstdre {
	font-family: Montserrat;
	font-size: 15px;
	font-weight: 500;
	line-height: 15px;
	color: #777777;
	padding: 20px 0 0 18px;
	a {
		display: inline-block;
		cursor: pointer;
		&:hover {
			text-decoration: underline !important;
		}
	}
}

.otherOffer-nw {
	.text-container {
		h1 {
			font-size: 23px;
			font-weight: 900;
			color: #222;
			max-width: 705px;
			padding-bottom: 20px;
		}
	}
	.icon-card {
		.card-label {
			width: 250px;
			height: auto !important;
			min-height: 300px !important;
			border: none;
			padding: 20px 0;
			border-radius: 5px;
			background: #fff;
			box-shadow: 0px 4px 8px #0000001a;
			justify-content: flex-end;
			text-align: center;
			h2 {
				font-size: 17px;
			}
			h6 {
				font-size: 13px;
				opacity: 0.5;
			}
			button {
				width: 200px;
				padding: 0.5rem;
				margin: 0.5rem 0rem;
				border-radius: 5px;
				border: none;
				font-weight: 900;
				background-color: #222222;
				color: #fff;
			}
		}
	}
	.btm-conetentor {
		span {
			display: block;
			text-align: center;
			font-weight: 900;
			opacity: 0.5;
			font-size: 18px;
		}
		button {
			width: 200px;
			padding: 0.5rem;
			margin: 0.5rem 0rem;
			border-radius: 5px;
			border: none;
			font-weight: 900;
			background-color: #222222;
			color: #fff;
		}
	}
}

.bankInfo-outer {
	#ccmonth {
		width: 200px;
		margin-top: 10px;
	}
	.tootflexbox {
		display: none;
	}
}

.portalOuter {
	background-color: #f2f2f2;
}
.gnrl-documents {
	.card-header {
		border-bottom: none;
		padding-left: 0;
	}
	.custom-select {
		width: 200px;
	}
}

.veryEmail {
	padding: 16px;
	.verifyMessage {
		display: flex;
		align-items: center;
		justify-content: center;
		height: calc(100vh - 51px);
		h2 {
			text-align: center;
		}
	}
}

ul.message-wrapper li:first-child span.timemes:after,
ul.message-wrapper li:nth-child(2) span.timemes:after {
	display: none;
}

ul.message-wrapper li span.timemes {
	align-items: baseline;
}

ul.message-wrapper li .has-attachment {
	margin: 0 0px 0 0;
	position: relative;
	top: 4px;
	right: 5px;
}

.single-chat-message.sent .attachment-link,
.single-chat-message.received .attachment-link {
	text-align: right !important;
	font-size: 13px;
	font-weight: 600;
}

.single-chat-message .attachment-link a {
	color: #ffa500;
}

.message-expand {
	position: relative;
}

.remove-attachment svg {
	width: 17px;
	color: red;
	cursor: pointer;
}

.message-expand .chat-loader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 11;
}

.message-expand .chat-loader img {
	width: 90px;
}

.content-outermsg {
	.chat-btn {
		input.form-control {
			margin-bottom: 15px;
		}
	}
}
.message-expand {
	span[data-letters] {
		margin-right: 10px;
	}
	.card {
		border: none;
	}
}

.sent {
	.chat-cont {
		border-bottom: 1px solid #e1e6ed9c;
		.name-title {
			text-align: right;
		}
		p {
			text-align: right;
		}
	}
	span.timemes {
		justify-content: flex-end;
	}
	.message-col {
		max-width: 90%;
		background: #feefd0;
	}
}

.single-chat-message {
	margin: 12px 0px;
	display: flex;
}

.single-chat-message.sent {
	justify-content: flex-end;
}

.message-col {
	border-radius: 10px;
	padding: 10px 12px;
	width: 90%;
}

.received .message-col {
	background: #e3e3e3;
}

.flex-chat-user {
	display: flex;
	align-items: center;
}

.activeChat {
	background: #feefd0;
}

.activeChat a {
	background: transparent !important;
}

.bank-card-wrapper {
	.cardDebit {
		._1p2LOEZXGASlX_Cf_pWIi7 {
			display: none;
		}
	}
}

.eduction-new {
	.input-container.prsnlEduNew {
		#nf-degree {
			margin-left: 0;
			margin-right: 0;
		}
		label[for="nf-major"],
		label[for="nf-school"],
		label[for=""] {
			padding-left: 0;
		}
	}
	.input-container {
		div.nprsnDregree {
			width: 100% !important;
			#nf-highestdegree {
				margin-left: 0;
			}
			label[for="nf-major"] {
				padding-left: 0;
			}
		}
	}
}

/*======== Responsive Css =======*/

@media (max-width: 3000px) and (min-width: 1921px) {
	/*========general-document-css=========*/

	.gnrl-documents {
		.nw-costumer {
			padding: 7rem 60px;
		}
		.user-name-container {
			h1 {
				padding-top: 0;
				font-size: 25px;
				color: #727272;
			}
		}
		.user-info-app {
			margin-top: 15px;
		}
		.user-info-app {
			h3 {
				margin-bottom: 0px;
			}
		}
		.file-card .main {
			padding: 0px 10px;
			h5 {
				font-family: Montserrat;
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
				line-height: 16px;
				letter-spacing: 0.30000001192092896px;
				text-align: center;
				color: #504940;
			}
		}
		.file-card {
			padding: 15px;
			min-height: auto;
		}
	}
}

@media (max-width: 1920px) {
	.sideBar-outer {
		width: 256px;
	}
	.csp-content-outer {
		width: calc(100% - 256px);
	}
	.nw-costumer {
		.container {
			max-width: 100%;
		}
		.noCurrent {
			padding-left: 15px;
		}
	}

	/*========general-document-css=========*/

	.gnrl-documents {
		.manualflex-gnrl {
			display: flex;
			flex-wrap: wrap;
		}
		.nw-costumer {
			padding: 7rem 60px;
		}
		.user-name-container {
			h1 {
				padding-top: 0;
				font-size: 25px;
				color: #727272;
			}
		}
		.user-info-app {
			margin-top: 20px;
		}
		.user-info-app {
			h3 {
				margin-bottom: 15px;
			}
		}
		.file-card .main {
			padding: 35px 10px 10px;
		}
		.file-card {
			padding: 15px;
			min-height: auto;
			.fileTitle {
				font-family: Montserrat;
				font-size: 15px;
				font-style: normal;
				font-weight: 800;
				line-height: 24px;
				letter-spacing: 0.328125px;
				text-align: center;
				color: #504940;
			}
		}
		.dcmnts-title {
			color: #101426;
		}
	}

	/*===============legal_documents==========*/
	.legal_documents {
		.legal-documents {
			display: flex;
			flex-wrap: wrap;
		}
		.nw-costumer {
			padding: 7rem 60px;
		}
		.file-card {
			width: 48%;
			margin: 1%;
			padding: 15px;
			.file-status {
				margin-top: 0;
				margin-bottom: 0;
			}
			.main {
				padding: 40px 10px;
				h4 {
					b {
						min-height: 20px;
					}
					font-size: 15px;
					color: #504940 !important;
				}
			}
		}
		.user-name-container {
			h1 {
				padding-top: 0;
				color: #727272;
			}
		}
		.user-info-app {
			margin-top: 20px;
		}
		.user-info-app {
			h3 {
				margin-bottom: 20px !important;
				// font-size: 18px;
			}
		}
		.legalDoc_main {
			.main {
				button {
					margin-top: 0;
					padding: 5px 35px;
				}
				a {
					margin-top: 10px;
				}
			}
		}
		.dcmnts-title {
			margin-bottom: 0 !important;
			color: #101426 !important;
		}
	}

	.nw-costumer .noCurrent {
		margin-bottom: 20px;
	}
}

@media (max-width: 1920px) and (min-width: 1800px) {
	.legal_documents {
		.file-card {
			width: 40%;
			margin: 1% 5%;
		}
	}
	.gnrl-documents {
		.file-card {
			width: 40% !important;
			margin: 1% 5% !important;
		}
	}
}

@media (min-width: 1500px) and (max-width: 1920px) {
	.bankInfo-outer {
		._2D_VfCL6PDramYdGNPlSSA {
			height: 350px !important;
		}
	}
}

@media (min-width: 1300px) and (max-width: 1920px) {
	/*========general-document-css=========*/
	.gnrl-documents {
		.file-card {
			padding: 23px;
			width: 48%;
			min-height: auto;
			margin: 1%;
		}
	}
	.content-outermsg {
		width: 57%;
	}
	.nav-outermsg {
		width: 43%;
	}
	ul.message-wrapper {
		max-width: 100%;
	}
}

@media (max-width: 1300px) {
	.user-info-app .legal_documents {
		padding-left: 40px;
	}
	.file-card {
		padding: 15px;
		width: 43%;
		min-height: auto;
		margin: 1%;
	}
	.gnrl-documents {
		.file-card .main {
			padding: 15px 10px;
		}
	}

	.highstdre {
		padding-left: 0;
		text-align: center;
	}
}

@media (min-width: 1340px) {
	.edunewcontianer {
		.content-container {
			flex: unset;
			display: block;
			width: 780px;
			margin: 0 auto;
		}
	}
}

@media (max-width: 1200px) {
	.nwsmry {
		margin-left: 30px;
	}
	.autoPlaysecton .tootflexbox {
		padding-left: 10px;
	}
	.bank-title {
		padding-left: 32px;
	}

	/*========16062021===========*/

	.the-content-container.nw-costumer {
		padding: 7rem 20px;
	}
}

@media (max-width: 1200px) and (min-width: 992px) {
	.nwPding {
		padding-right: 65px;
		font-size: 12px;
	}
	.cHeader-highlight {
		left: 63.14%;
	}
	.n-credit .cHeader-highlight {
		font-size: 11px;
		line-height: 13px;
	}
}
@media (max-width: 1100px) {
	.nwsmry {
		max-width: 360px;
		margin-left: 15px;
	}
	.payment-history {
		width: 100%;
	}
	.bnk-section .content-container {
		margin: 0;
	}
	.bank-title {
		padding-left: 0;
	}

	/*===message page ====*/
	ul.message-wrapper {
		max-width: auto;
	}
	.message-expand {
		padding-left: 30px;
	}
	.bankInfo-outer {
		.bankwarp {
			padding-bottom: 50px;
		}
	}
	.messaeOuter {
		flex-wrap: wrap;
	}
}

/*========16062021===========*/

@media (max-width: 1024px) {
	.the-content-container.nw-costumer {
		.create_new {
			display: block;
			float: left;
			margin-top: 0;
		}
	}

	/*===========genral-documents===========*/
	.gnrl-documents {
		.nw-costumer {
			padding: 6rem 15px;
			.file-card {
				width: 48%;
			}
		}
	}
	.legal_documents {
		padding-left: 0;
		.nw-costumer {
			padding: 6rem 15px;
		}
	}

	.portalOuter {
		.user-name-container {
			h1 {
				padding-top: 0;
			}
		}
	}
}

@media (max-width: 991px) {
	/*=========customer portal toggle button ==========*/
	.top_navOuter {
		.bars {
			position: fixed;
			left: 10px;
			z-index: 9999;
			width: 35px;
			height: 35px;
			border: none;
			outline: none;
			background-color: transparent;
			top: 20px;
			img {
				width: 100%;
			}
		}
	}
	.sidBrexpand {
		.c-sidebar {
			margin-left: 0 !important;
		}
	}

	.portalOuter {
		.user-name-container {
			h1 {
				padding-top: 20px;
			}
		}
	}

	.bars {
		display: block;
	}

	.nwPding {
		padding-right: 96px;
		font-size: 12px;
	}
	.cHeader-highlight {
		left: 63.14%;
	}
	.n-credit .cHeader-highlight {
		font-size: 11px;
		line-height: 13px;
	}

	/*==== Bank Page ======*/

	.new-wrpsbnk {
		.nwsmry {
			margin-left: 30px;
			margin-top: 49px;
		}
		.page-title {
			margin-top: 0;
		}
		.content-title {
			margin: 5px 0 15px 0;
		}
	}
	.wrapper {
		padding: 2rem;
	}
	.page-title {
		margin-top: 0;
	}

	/******** documents page *******/
	.file-card {
		width: 100%;
		margin: 30px 0;
	}

	/*==== messaging page ====*/

	.msg-spacing {
		padding: 6rem 0rem;
	}

	.tbl-space {
		padding: 0rem !important;
	}
	.tbl-space .title {
		margin-bottom: 20px;
		margin-top: 110px;
	}
	/*========16062021===========*/
	.main-outer-csp {
		flex-wrap: wrap;
	}
	.sideBar-outer {
		width: 100%;
	}
	.csp-content-outer {
		width: 100%;
	}
	.nw-costumer {
		padding: 5rem 15px !important;
	}

	/*===========genral-documents===========*/
	.gnrl-documents {
		.nw-costumer {
			.file-card {
				width: 100%;
				margin: 0 0 20px 0;
			}
		}
		.user-info-app {
			h3 {
				margin-bottom: 10px;
			}
		}
	}

	/*=========bank info ====*/
	.bankInfo-outer {
		.autoPlaysecton {
			.tootflexbox {
				padding-left: 30px;
			}
		}
	}

	.the-content-container.nw-costumer {
		.user-name-container {
			h4 {
				margin-bottom: 20px;
			}
		}
	}
}

@media (max-width: 914px) {
	.new-wrpsbnk .nwsmry {
		margin-top: 0;
	}
	.autopay {
		margin-bottom: 30px;
	}
	.bankwarp {
		padding-bottom: 40px;
	}
}

@media (max-width: 840px) {
	.the-content-container {
		padding: 7rem 0px;
	}
	.nwPding {
		font-size: 11px;
	}
	.n-credit .cHeader-highlight {
		font-size: 9px;
		line-height: 14px;
	}
	.cMain {
		padding: 12px 10px;
	}
	.btn-copylnik {
		right: 10px;
		font-size: 7px;
		padding: 4px 6px;
	}
	.n-referal {
		font-size: 7px;
		padding: 9px 3px;
	}
	.cmn-padding .mini-statement-table th {
		font-size: 10px;
	}
}

@media (max-width: 807px) {
	.trophy-image {
		width: 100px;
		left: -40px;
		top: 50px;
	}
	.ofr-spcing {
		padding: 0 0 45px 50px;
	}
	.ofr-spcing h1 {
		font-size: 20px;
	}
	.ofr-spcing h3 {
		font-size: 16px;
	}
}

@media (max-width: 767px) {
	.input-container-pr > div {
		width: 50%;
	}
	.pb-sm-3 {
		padding-bottom: 16px;
	}
	.pb-sm-5 {
		padding-bottom: 24px;
	}
	.navbar {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding-top: 0;
		padding-bottom: 0;
		.navbar-brand {
			width: 250px;
			img {
				width: 100%;
			}
		}
	}
	.navbar-nav.ml-auto {
		margin-left: 0 !important;
	}
	.form-inline {
		flex-wrap: nowrap;
	}
	.prmnl {
		padding-left: 0;
	}
	.the-content-container {
		padding: 2rem 0px;
	}

	/*==== messaging page ====*/

	.msg-spacing {
		flex-wrap: wrap;
	}
	ul.message-wrapper {
		max-width: 100%;
		width: 100%;
	}

	/*===========genral-documents===========*/

	.legal_documents {
		.nw-costumer {
			.file-card {
				width: 100%;
			}
		}
		.file-card {
			margin: 0 0 20px 0 !important;
		}
	}
	.error-page-head h3 {
		font-size: 17px !important;
	}
	.error-page-head img {
		max-width: 65px;
	}
	.error-page-text-content {
		padding: 0 5px;
	}
	.news-style-one .image-box {
		height: auto !important;
	}
	.news-style-one .image-box img {
		height: auto !important;
	}
	.news-style-one {
		padding: 0;
		margin-bottom: 30px !important;
	}
	.news-style-one .lower-content .post-meta {
		font-size: 13px !important;
		margin-bottom: 5px !important;
	}
	.news-style-one .lower-content h3 {
		font-size: 16px !important;
	}
	.news-style-one .text span {
		font-size: 14px !important;
	}
	.blog-detail-col {
		padding: 30px 15px 100px 15px !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.blog-detail-col .image-column {
		width: 100%;
		height: auto;
		margin: 0 0 10px 0 !important;
	}
	.blog-detail-col h1 {
		font-size: 20px !important;
		font-weight: 600;
	}
	.blog-detail-col .text p {
		font-size: 14px;
	}
	.blog-header-col {
		margin: 0 0 10px !important;
	}
	.plaid-main-container h5 {
		font-size: 15px;
	}
	.plaid-main-container, .plaid-main-container h6 {
		font-size: 12px;
	}
	.plaid-main-container h4 {
		font-size: 14px;
	}
	.plaid-main-container .right-content {
		padding: 0 13px !important;
		margin-top: 10px;
	}
	.plaid-main-container .button-container {
		position: fixed;
		left: 0;
		bottom: 0px;
		padding: 10px 10px !important;
		width: 100%;
		background: #fff;
	}
	.plaid-main-container .button-container .custom-button {
		font-size: 12px;
		width: auto;
		padding: 6px 10px;
	}
	.plaid-main-container .button-container p {
		font-size: 12px;
		margin-top: 5px !important;
	}
	.plaid-main-container .button-container p img {
		width: 13px;
	}
	.plaid-main-container {
		margin-bottom: 60px !important;
	}
}

@media (max-width: 767px) {
	.bankInfo-outer {
		.new-wrpsbnk {
			flex-wrap: wrap;
		}
		.bank-card-wrapper {
			width: 100%;
		}
		.nwsmry {
			max-width: 100%;
			width: 100%;
		}
		.card-details {
			width: 100%;
		}
	}
	.message-mainouter {
		.content-outermsg {
			width: 100%;
		}
		.nav-outermsg {
			width: 100%;
		}

		.send-btn {
			.pl-5 {
				padding-left: 0;
			}
		}
	}

	.product-wrappers {
		.product-sectionOuter {
			.purpose-row {
				.icon-card {
					width: calc(99% / 2 - 2%);
					.card-label {
						.inboxtitle {
							font-size: 16px;
							line-height: 23px;
						}
					}
				}
			}
			.product-title {
				line-height: 35px;
			}
		}
	}
	.top_navOuter {
		.bars {
			top: 10px;
		}
	}
	.Sidebar {
		margin-top: 57px !important;
	}
	.tbl-space .title {
		margin-top: 70px;
	}
}

@media (max-width: 700px) {
	.eduction-new {
		.input-container.prsnlEduNew {
			.edubox {
				width: 100%;
			}
		}
	}
}

@media (max-width: 575px) {
	.bnk-section.wrapper {
		padding: 5rem 1rem 2rem 1rem !important;
		.bnkwrap-ol {
			flex-wrap: wrap;
			#ccmonth {
				margin-right: 5px;
			}
			.payNow {
				margin: 10px 0 0 0 !important;
			}
		}
		.edit-delete {
			top: 66px;
			right: 0;
			z-index: 2;
		}
	}
	.new-wrpsbnk {
		flex-wrap: wrap;
		width: 100%;
		justify-content: center;
	}
	.new-wrpsbnk .nwsmry {
		margin-left: 0;
		max-width: 100%;
	}
	.autoPlaysecton .credit-cards-container {
		width: 100%;
	}
	.autoPlaysecton .tootflexbox {
		width: 100%;
		padding-left: 0;
	}

	.addition-title {
		text-align: center;
	}
	.addi-wrp {
		padding-right: 0px !important;
	}
	.tooltipBox {
		margin: 15px auto 15px auto;
	}
	.content-title {
		font-size: 15px;
	}
	#page-section {
		background-color: #ebedef !important;
	}

	/*===message page ====*/
	.message-expand .msg-content p {
		font-size: 12px;
		line-height: 15px;
	}
	.messaeOuter {
		flex-wrap: wrap;
	}
	span.timemes {
		margin-left: 0;
	}
	span.timemes:after {
		left: auto !important;
		right: -15px !important;
		top: 3px !important;
	}
	.message-expand,
	.message-wrapper {
		padding-left: 15px;
	}

	.table-responsive table.table.table-hover {
		tr {
			td {
				&:first-child {
					font-family: Montserrat;
					font-size: 9px;
					font-style: normal;
					font-weight: 600;
					line-height: 16px;
					letter-spacing: 0.08749999850988388px;
					text-align: center;
					color: #222;
				}
				font-family: Montserrat;
				font-size: 9px;
				font-style: normal;
				font-weight: 500;
				line-height: 21px;
				letter-spacing: 0.08749999850988388px;
				text-align: center;
				color: #222;
				border-top: none;
				padding: 3px;
			}
		}
	}

	.table-responsive table.table.table-hover thead tr th {
		font-size: 9px;
		padding: 2px;
	}

	/*==============16062021============*/

	.nw-costumer {
		.application-leftcontent {
			.cCard {
				overflow-y: hidden;
			}
			.cMain {
				width: 575px;
			}
		}
	}
	.legal_documents {
		.nw-costumer {
			padding: 4rem 15px !important;
		}
		.file-card {
			.file-status {
				font-size: 9px;
				margin-top: 5px;
			}
		}
	}
	.gnrl-documents {
		.nw-costumer {
			padding: 2rem 15px !important;
		}
	}

	.the-content-container.nw-costumer {
		.user-name-container {
			h1 {
				padding-top: 0px;
			}
		}
	}

	/*============= bank info =========*/

	.bankInfo-outer {
		.nwsmry {
			margin-left: 0;
		}
		.transitionOuter {
			li {
				padding: 10px 0;
			}
		}
	}

	.message-mainouter .msg-spacing {
		padding: 4rem 20px;
	}

	.PI_page {
		.m-4 {
			margin: 0 !important;
		}
	}

	.navbar .navbar-brand {
		margin-left: 20px;
	}
	.Sidebar {
		margin-top: 53px !important;
	}

	/*===============layout page==========*/

	#intro-section h1 {
		font-size: 40px;
	}
	#intro-section .cards-container {
		flex-direction: row;
		flex-wrap: wrap;
	}

	.eduction-new .input-container .edubox {
		width: 100%;
	}
	.card-label img {
		margin: 0rem;
	}
	.text-container.spaced h1 {
		font-size: 27px;
	}
}

@media (max-width: 500px) {
	@-moz-document url-prefix() {
		.mzn-support {
			section {
				-moz-transform: inherit;
			}
			#intro-section {
				.text-container {
					margin: 0;
					padding: 0;
				}
				.content-container {
					justify-content: flex-start;
				}
				h1 {
					font-size: 30px;
				}
				h3 {
					font-size: 23px;
				}
			}
			.wrapper {
				padding: 2rem 15px;
			}
			.text-container {
				h1 {
					font-size: 25px;
				}
				h3 {
					font-size: 20px;
				}
				padding-right: 0;
				padding-left: 0;
			}
			.input-border-error {
				margin: 1rem 0;
			}
		}
	}
	.mzn-support {
		.input-border-error {
			margin: 1rem 0;
		}
		.custom-label {
			padding-left: 0;
		}
	}
}

@media (max-width: 460px) {
	.legal_documents {
		.file-card {
			margin: 0 0 20px 0;
			.header {
				flex-wrap: wrap;
			}
		}
	}
	.dcmnts-title {
		font-size: 13px !important;
		text-align: left;
	}
	.legal_documents .file-card .main h4 {
		font-size: 13px;
	}
	.file-card .file-status {
		padding: 6px 20px;
	}

	/* ================= layout page (application flow) =========== */
	@-moz-document url-prefix() {
		.mzn-support {
			.text-container h1 {
				font-size: 18px;
				font-weight: 500;
			}
			.icon-card {
				.card-label {
					h3 {
						font-size: 14px;
					}
				}
			}
			.form-check {
				zoom: unset;
				-moz-transform: unset;
			}
			.mzlnew {
				.icon-card {
					margin-left: 0;
				}
			}
		}
	}
	.mzn-support {
		// .text-container h1 {
		//     font-size: 18px;
		//     font-weight: 500;
		// }
		.icon-card {
			.card-label {
				h3 {
					font-size: 15px;
				}
			}
		}
	}
}

@media (max-width: 716px) {
	.trophy-image {
		position: static;
		margin: 0 auto 30px auto;
	}
	.ofr-spcing {
		padding: 0;
	}
}

@media (max-width: 360px) {
	.message-mainouter {
		.send-btn {
			.pl-5 {
				padding-left: 0 !important;
			}
		}
		.emailinfo span {
			font-size: 11px;
		}

		.message-expand .name-title {
			font-size: 23px;
		}

		.nwPding {
			padding-right: 155px;
		}
	}

	.purposeWrappers {
		.purpose-outer {
			.purpose-row {
				.icon-card {
					width: calc(90% / 2 - 2%);
				}
			}
		}
	}
}
@media (max-width: 337px) {
	.product-wrappers {
		.product-sectionOuter {
			.purpose-row {
				display: flex;
				flex-wrap: wrap;
				.icon-card {
					margin: 0 0 20px 10px;
				}
			}
		}
	}
}

[data-letters]:before {
	content: attr(data-letters);
	display: inline-block;
	font-size: 1em;
	width: 2.5em;
	height: 2.5em;
	line-height: 2.5em;
	text-align: center;
	border-radius: 50%;
	background: rgba(72, 128, 255, 0.24);
	vertical-align: middle;
	color: #4880ff;
	font-weight: 600;
}

.card-desc {
	max-height: 40px;
	overflow: hidden;
}

.fade:not(.show) {
	opacity: 1;
}

.message-expand .single-chat-message:first-child {
	max-width: 100%;
}

.single-chat-message.message-item0 .message-col {
	max-width: 100%;
	width: 100%;
	background: transparent;
	padding: 0;
	margin-bottom: 12px;
}

.view-ticket-title {
	margin: 3px 0 0 0;
	font-size: 30px;
	color: #25252b;
}

.timemes.view-ticket-time {
	color: #686871;
	margin: 15px 0 0 0;
}

.view-ticket-title::first-letter {
	text-transform: uppercase;
}

.messaeOuter .name-title::first-letter {
	text-transform: uppercase;
}

.flex-chat-user .emailid p {
	color: #25252b;
}

.unread-ticket-item,
.unread-ticket-item a {
	background: #fcfaf7 !important;
}

.unread-ticket-item.ticket-read-cls,
.unread-ticket-item.ticket-read-cls a {
	background: transparent !important;
}

.unreadIndicator {
	display: inline-block;
	height: 12px;
	width: 12px;
	background: #ff9300;
	border-radius: 50%;
	margin-top: 10px;
}

.unread-ticket-item.ticket-read-cls .unreadIndicator {
	display: none;
}

/* ====== IE Support css ======= */

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.custom-card {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	section {
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	#page-section {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	#intro-section {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
	.save-exit-button-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	.content-container {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 1000px;
	}
	.input-container {
		display: flex;
	}
	.warning-text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.button-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.radios-container {
		display: flex;
		flex-direction: column;
	}
	.radios-container-horizontal {
		display: flex;
		flex-direction: row;
	}
	.cards-container {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.base-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.footer {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.content-container {
		flex: auto;
	}
}
#root > .overflow {
	overflow-x: hidden;
	.progress {
		background-color: #fdf0df !important;
		.progress-bar {
			background-color: #ff9300 !important;
		}
	}
}
.no-scroll {
	&.tiles-3 {
		& .icon-card {
			width: 31.33% !important;
			margin: 0px 1% 2% !important;
		}
	}
	&.product-sectionOuter {
		& .product-title {
			line-height: 30px;
		}
		.icon-card {
			margin-bottom: 10px !important;
			margin-left: 10px !important;
		}
	}
	.content-container {
		margin: 8px auto;
		width: 100%;
		.readable {
			line-height: 1.8rem;
			font-size: 14px;
			color: #0b162b;
		}
		.text-container {
			padding: 0px 1rem !important;
			h1 {
				font-size: 22px;
				line-height: 30px;
				color: #0b162b;
			}
			h3 {
				font-size: 18px;
			}
		}

		.custom-label {
			margin-bottom: 0;
			color: #777777;
		}
		.custom-input {
			margin-top: 5px;
			margin-bottom: 8px;
			padding: 9px 12px;
			line-height: 18px;
			font-weight: 600;
			color: #222;
			&::-webkit-autofill {
				background-color: #f9f9f9 !important;
			}
			// &:-internal-autofill-selected {
			//     box-shadow: 0 0 0px 1000px #f9f9f9 inset !important;
			//     border: 3px solid rgba(255, 147, 0, 0.3) !important;
			// }
			&#nf-graduation {
				padding-right: 8px;
			}
			&::-webkit-input-placeholder {
				color: #ccc;
			}
			&:-ms-input-placeholder {
				color: #ccc;
			}
			&::placeholder {
				color: #ccc;
			}
			// &::-webkit-datetime-edit-day-field{
			//     color: #ccc;
			//     &[aria-valuenow="12"]{
			//         color: red;
			//     }
			// }
			// &[aria-valuenow]{
			//     color: #222;
			// }
			// &::-webkit-datetime-edit-month-field{
			//     color: #ccc;
			//     [aria-valuetext]{
			//         color: red;
			//     }
			// }
			// &::-webkit-datetime-edit-month-field[aria-valuetext]{
			//     color: #222;
			// }
			// &::-webkit-datetime-edit-year-field{
			//     color: #ccc;
			//     &[aria-valuetext]{
			//         color: red;
			//     }
			// }
			// &::-webkit-datetime-edit-year-field[aria-valuetext]{
			//     color: #222;
			// }
			// [aria-valuenow]{
			//     color: red;
			// }
			&::-webkit-calendar-picker-indicator {
				color: #5a5a5a;
			}
		}
		#loan-amount {
			// min-width: 375px;
			width: calc(100% - 16px);
		}
		.manual-rwx {
			align-items: baseline;
			width: 100%;
			button.btn {
				margin-top: 0 !important;
				margin-bottom: 0 !important;
				line-height: 30px;
				img {
					max-width: 16px;
				}
			}
			.custom-input.search-input {
				margin-right: 10px;
				margin-left: 0;
			}
		}
		.input-border-error {
			margin-top: 5px;
			margin-bottom: 8px;
			padding: 8px 12px;
			line-height: 18px;
			font-weight: 600;
		}
		.edubox {
			padding: 0 1rem !important;
			.custom-input.custom-input-container {
				padding: initial !important;
				margin: 5px 0 8px;
				input[type="text"] {
					margin: 6px 0;
					font-size: 0.785rem;
					font-weight: 600;
				}
				&.input-mar {
					input[type="text"] {
						margin: 9px 0;
					}
				}
			}
			.custom-label {
				padding-left: 0;
			}
			> .custom-input {
				margin-left: 0;
				margin-right: 0;
			}
			&.self-employed {
				width: 100%;
			}
			.custom-input-container.search-input-container {
				position: relative;
				padding-right: 30px !important;
				&:after {
					content: "\f002";
					font-family: fontawesome;
					right: 5px;
					top: 10px;
					position: absolute;
					color: #5a5a5a;
					width: 20px;
					height: 20px;
					font-size: 18px;
				}
				.css-1hb7zxy-IndicatorsContainer {
					display: none;
				}
				.css-1wy0on6 {
					display: none;
				}
				.css-1gtu0rj-indicatorContainer {
					display: none;
				}
				.css-tlfecz-indicatorContainer {
					display: none;
				}
			}
			.amount-input {
				padding: 0;
				position: relative;
				.custom-input {
					margin-left: 0;
				}
				&:after {
					left: 10px;
				}
			}
		}
		.address-input-container {
			.input-container {
				min-width: 680px;
			}
		}
		button.custom-button {
			margin-top: 4px;
			margin-bottom: 4px;
		}
		.text-container {
			padding: 0.5rem 1rem;
		}
		.error-validation {
			margin-bottom: 0;
			text-align: center;
		}
		.form-check {
			margin: 8px 1rem;
			color: #222;
		}
		.location-search-container {
			position: relative;
			.autocomplete-dropdown-container {
				position: absolute;
				top: 100%;
				.loading {
					background-color: #fff;
					border: 1px solid #cccccc;
					border-radius: 3px;
					box-shadow: 0px 0px 6px rgba(224, 224, 224, 0.5);
					font-size: 18px;
					line-height: 20px;
					padding: 10px 15px;
				}
				&.has-options {
					border: 1px solid #cccccc;
					border-radius: 3px;
					box-shadow: 0px 0px 6px rgba(224, 224, 224, 0.5);
					overflow: hidden;
					background: #fff;
					padding: 0;
					max-width: 395px;
					z-index: 1;
					.suggestion-item {
						font-size: 18px;
						color: #333333;
						line-height: 20px;
						padding: 10px 15px !important;
						&:hover {
							background-color: #f7f7f7 !important;
						}
						&:focus {
							background-color: #555555 !important;
							color: #ddd !important;
						}
					}
					.suggestion-item--active {
						font-size: 18px;
						color: #333333;
						line-height: 20px;
						padding: 10px 15px !important;
						&:hover {
							background-color: #f7f7f7 !important;
						}
						&:focus {
							background-color: #555555 !important;
							color: #ddd !important;
						}
					}
				}
			}
			.search-input {
				position: relative;
				min-width: 375px;
				padding: 0;
				&:after {
					content: "\f002";
					font-family: fontawesome;
					right: -5px;
					top: 10px;
					position: absolute;
					color: #5a5a5a;
					width: 20px;
					height: 20px;
					font-size: 18px;
				}
				.custom-input {
					padding-right: 30px;
				}
			}
		}
		.css-26l3qy-menu {
			max-width: 395px;
			width: 100%;
			background-color: #fff;
			.css-gg45go-NoOptionsMessage {
				color: #333333;
				line-height: 20px;
				padding: 10px 15px !important;
				font-weight: 500;
				font-size: 15px;
			}
			.css-1175pzl-option {
				color: #333333;
				line-height: 16px;
				padding: 10px 15px !important;
				margin: 0;
				width: 100%;
				cursor: pointer;
				font-weight: 500;
				font-size: 15px;
				&:hover {
					background-color: #f7f7f7 !important;
				}
				&:focus {
					background-color: #555555 !important;
					color: #ddd !important;
				}
			}
			.css-yt9ioa-option {
				color: #333333;
				line-height: 16px;
				padding: 10px 15px !important;
				margin: 0;
				width: 100%;
				cursor: pointer;
				font-weight: 500;
				font-size: 15px;
				&:hover {
					background-color: #f7f7f7 !important;
				}
				&:focus {
					background-color: #555555 !important;
					color: #ddd !important;
				}
			}
			.css-18h6s8t-option {
				background-color: #555555;
				color: #ddd;
				font-weight: 500;
				margin: 0;
				line-height: 16px;
				padding: 10px 15px !important;
				font-size: 15px;
			}
			.css-9gakcf-option {
				background-color: #555555 !important;
				color: #ddd !important;
				line-height: 16px;
				padding: 10px 15px !important;
				margin: 0;
				width: 100%;
				cursor: pointer;
				font-weight: 500;
				font-size: 15px;
			}
			.css-1n7v3ny-option {
				line-height: 16px;
				padding: 10px 15px !important;
				margin: 0;
				width: 100%;
				cursor: pointer;
				font-weight: 500;
				background-color: #f7f7f7;
				font-size: 15px;
			}
			.css-4ljt47-MenuList,
			.css-ei3qqm-MenuList,
			.css-11unzgr {
				border: 1px solid #cccccc;
				border-radius: 3px;
				box-shadow: 0px 0px 6px rgba(224, 224, 224, 0.5);
				background: #fff;
				padding: 0;
				font-size: 15px;
			}
		}
		.css-1wa3eu0-placeholder,
		.css-1wa3eu0-placeholder {
			color: #ccc;
		}
		.css-1pahdxg-control {
			padding-right: 0;
			padding: 0 1rem;
			display: flex;
			-ms-flex-align: center;
			background-color: #f9f9f9;
			border-color: transparent;
			border-radius: 4px;
			border-style: none;
			border-width: 0;
			cursor: default;
			border: none;
			display: -ms-flexbox;
			-webkit-box-flex-wrap: wrap;
			box-shadow: none;
		}
		.css-tlfecz-indicatorContainer,
		.css-1gtu0rj-indicatorContainer {
			margin-right: 10px !important;
		}
		.icon-card {
			margin-bottom: 10px;
		}
		.css-1uccc91-singleValue {
			color: #222;
		}
		.error-container {
			text-align: center;
		}
		.amount-input {
			padding: 0;
			position: relative;
			.custom-input {
				// margin-left: 0;
				// margin-right: 0;
				padding-left: 25px;
			}
			&:after {
				content: "$";
				position: absolute;
				left: 24px;
				top: 8px;
				position: absolute;
				color: #5a5a5a;
				width: 20px;
				height: 20px;
				font-size: 18px;
				font-weight: 600;
			}
			&.top-12 {
				&:after {
					top: 12px;
				}
			}
			&.left-10 {
				&:after {
					top: 12px;
				}
			}
		}

		// .no-left-padding {
		//     .custom-label {
		//         // padding-left: 0;
		//     }
		// }
		&.loan-success {
			margin: 0;
			.trophy-image {
				width: 125px;
			}
			.text-container.ofr-spcing {
				padding-left: 100px !important;
				h1 {
					margin-bottom: 0;
				}
				h3 {
					color: #0b162b;
				}
			}
			.icon-card {
				margin-bottom: 5px;
				.card-label {
					height: auto;
					width: 275px;
					> div {
						padding: 3px 5px;
						h5 {
							margin-bottom: 0;
						}
					}
					h4 {
						color: #ff9300;
						font-family: Montserrat;
						font-size: 18px;
						font-style: normal;
						font-weight: 700;
						line-height: 22px;
						letter-spacing: 0px;
						margin-bottom: 0;
					}
				}
			}
			.form-check {
				padding-left: 58px;
				margin-top: 0;
				margin-bottom: 0;
			}
		}
	}
	.button-container {
		padding: 0.5rem 1rem;
	}
	.error-message {
		position: relative;
		padding: 5px 8px;
		text-align: center;
	}
	.card-label {
		width: 140px;
		height: 130px;
		margin-bottom: 0;
		color: #222222;
		font-weight: 600;
		img {
			width: auto;
			height: 65px;
			margin: 8px;
			margin-top: 0px;
			margin-bottom: 0px;
			padding-top: 0;
		}
		.inboxtitle {
			font-size: 16px !important;
			line-height: 16px !important;
		}
		h2 {
			font-size: 14px;
		}
		&.inboxtitle {
			& h3 {
				font-family: Montserrat;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: 24px;
				letter-spacing: 0px;
				text-align: center;
				color: #222222;
				padding: 5px 8px;
			}
		}
	}
	.align-baseline {
		align-items: baseline;
	}
	.offers-button {
		button {
			margin: 0;
		}
	}
}
#page-section {
	padding: 12px 32px;
	.load-class {
		height: auto;
		width: auto;
	}
}
.otherOffer-nw {
	.no-scroll {
		.icon-card {
			&.change-loan {
				label.card-label {
					padding: 0;
					min-height: 1px !important;
					h2 {
						margin-bottom: 0;
						font-size: 16px;
						line-height: 16px;
					}
				}
			}
		}
	}
}
.sign-in-box {
	padding-top: 50px;
	padding-bottom: 50px;
	&.base-container {
		.user-input {
			padding: 9px 0.5rem;
			font-weight: 600;
			color: #222 !important;
			&::-webkit-autofill {
				background-color: #f9f9f9 !important;
			}
			&::-internal-input-suggested {
				font-weight: 600;
				color: #222 !important;
				background-color: #fff;
				box-shadow: 0px 0px 0px 1000px #fff inset !important;
				border: 1px solid #f2f2f2;
			}
			&:-internal-autofill-selected {
				background-color: #fff;
				box-shadow: 0px 0px 0px 1000px #fff inset !important;
				border: 1px solid #f2f2f2;
			}
			&::-webkit-input-placeholder {
				color: #ccc;
			}
			&:-ms-input-placeholder {
				color: #ccc;
			}
			&::placeholder {
				color: #ccc;
			}
		}
	}
}
.spaced {
	color: #0b162b;
	&.equifax {
		h1 {
			font-size: 36px;
			margin-bottom: 30px;
		}
		h2 {
			font-size: 22px;
			font-weight: 700;
		}
		h4 {
			font-size: 20px;
			font-weight: 500;
		}
	}
}
.loan-slider {
	margin-top: 60px;
	padding-left: 20px;
	width: calc(100% - 16px);
	.MuiSlider-rail {
		background-color: rgba(255, 147, 0, 0.3);
		height: 12px;
	}
	.MuiSlider-thumb {
		width: 24px;
		height: 24px;
		background-color: #ff9300;
		margin-top: -7px;
	}
	.MuiSlider-thumb.Mui-focusVisible,
	.MuiSlider-thumb:hover,
	.MuiSlider-thumb.MuiSlider-active {
		box-shadow: 0px 0px 0px 8px rgba(255, 147, 0, 0.3) !important;
	}
	.MuiSlider-valueLabel {
		left: calc(-50% - 6px);
		top: -62px;
		& > span {
			width: 60px !important;
			height: 60px !important;
			background-color: #ff9300 !important;
		}
	}
	.MuiSlider-mark {
		height: 12px;
		width: 12px;
		border-radius: 50%;
		background-color: #ff9300;
	}
	.MuiSlider-track {
		height: 12px;
		background-color: #ff9300;
		opacity: 0.6;
	}
	.MuiSlider-markLabel {
		margin-top: 5px;
		font-weight: 600;
		color: #ccc;
		&.MuiSlider-markLabelActive {
			color: #222;
		}
	}
}
.Overlay.sandbox {
	.Artwork {
		img {
			margin-top: 0;
			max-width: 195px;
			height: auto;
		}
	}
	#a11y-title {
		font-size: 20px;
	}
	.Overlay__content.Overlay__content--is-large {
		max-width: 500px;
		width: 100%;
		height: auto;
	}
	.CheckList-module__secondaryText {
		margin-bottom: 8px;
		margin-top: 0;
	}
}

iframe#plaid-link-iframe-2 {
	overflow-y: scroll;
	height: 90vh !important;
	width: 400px !important;
	margin: 0 auto;
	top: 50% !important;
	transform: translateY(-50%);
}
.navbar-brand {
	img {
		max-height: 39px;
	}
}

.navbar {
	padding: 1rem 1rem;
	min-height: 95px;
	.navbar-nav {
		align-items: center;
		.alert {
			margin: 0 25px 0;
			padding: 10px;
			span {
				cursor: pointer;
				border: solid 1px;
				padding: 3px 6px;
				border-radius: 5px;
				background-color: orange;
				color: white;
				font-weight: 600;
				margin-left: 7px;
			}
		}
	}
	.dropdown {
		.btn {
			outline: none !important;
			box-shadow: none !important;
			padding: 0px;
		}
	}
}

.header-notification-btn {
	padding: 0;
	position: relative;
}

.header-notification-badge.badge {
	position: absolute;
	top: -3px;
	right: -10px;
	background: #f78f1e;
	color: white;
	min-height: 17px;
	min-width: 17px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header-action-icon {
	margin-left: 20px;
	.dropdown-toggle.btn::after {
		display: none;
	}
}

.modal-header {
	background-color: transparent !important;
	border: none;
	padding-bottom: 0px;
	.modal-title {
		font-weight: 700;
		color: #000;
		font-size: 18px;
		text-transform: uppercase;
		margin: 0 0 10px 0;
	}
}

.modal-content {
	border: none;
	border-radius: 15px;
}

.modal-footer {
	text-align: center;
	justify-content: center;
	border: none;
	.btn {
		min-width: 140px;
		border: solid 1px #ddd9d9;
		border-radius: 60px;
		padding: 8px 0;
		font-weight: 700;
	}
	.notification-btn-cancel {
		color: #8f9bb2;
	}
	.notification-btn-ok {
		background-color: #ffa500;
		border-color: #ffa500 !important;
	}
}

.custom-notification-sweetalert {
	.modal-content {
		border: none;
		border-radius: 15px;
		padding: 15px 0;
	}
	.modal-footer {
		text-align: center;
		justify-content: center;
		border: none;
		.btn {
			min-width: 140px;
			border: solid 1px #ddd9d9;
			border-radius: 60px;
			padding: 8px 0;
			font-weight: 700;
		}
	}
	.custom-notification-title {
		font-weight: 700;
		color: #000;
		font-size: 18px;
		text-transform: uppercase;
		margin: 0 0 10px 0;
	}
	.custom-notification-text {
		color: #777;
		margin: 0;
		font-size: 14px;
	}
	.custom-notification-btn-cancel {
		color: #8f9bb2;
	}
	.custom-notification-btn-ok {
		background-color: #ffa500;
		border-color: #ffa500 !important;
	}
	.custom-notification-no-header {
		padding: 0;
		border: none;
	}
	.modal-header {
		.close {
			display: block;
			font-size: 26px;
			padding: 0;
			position: absolute;
			right: 15px;
			margin: 0 !important;
			top: 10px;
			color: #8f9bb2;
			z-index: 1;
		}
	}
}

.vertical-alert {
	.modal-dialog {
		align-items: center;
		display: flex;
		min-height: calc(100% - 3.5rem);
	}
}

.c-sidebar-minimizer {
	position: absolute;
	top: 10px;
	width: auto;
	right: -65px;
	background: transparent !important;
	&:hover {
		background: #ddd !important;
		color: #a4a4a4 !important;
	}
}

.c-sidebar-nav-link {
	z-index: 1;
}

.c-sidebar-minimized {
	.c-sidebar-minimizer {
		position: absolute !important;
		bottom: initial !important;
	}
}

.c-sidebar .c-sidebar-minimizer::before {
	background-image: url("../assets/icons/minimizer.png") !important;
	background-size: 75%;
}

.c-wrapper {
	padding-left: 25px;
}

.tbl-space {
	padding: 5rem 60px;
}

.top_navOuter,
.gnrl-documents,
.sche-bg {
	.navbar {
		padding-right: 45px;
		box-shadow: 1px 3px 15px #ececec;
	}
}

.PageLayout .col > .m-4.card {
	margin: 0 !important;
}

.PageLayout {
	padding-left: 40px;
	margin-top: 115px !important;
	padding-right: 30px;
}

.nw-costumer > div:first-child {
	padding-left: 15px;
	padding-right: 15px;
}

#page-section {
	padding-left: 75px !important;
}

.nw-costumer {
	padding-right: 30px !important;
}

.card {
	border: none;
	border-radius: 15px;
	overflow: hidden;
	box-shadow: 1px 3px 15px #d5d5d5;
}

.c-sidebar.c-sidebar-fixed {
	z-index: 999;
}

.user-info-app {
	.dcmnts-title {
		font-size: 15px;
		img {
			margin-right: 5px;
		}
	}
}

.file-card .main h5 .btn {
	margin-top: 0 !important;
	font-size: 13px !important;
}

.bedit-deleteldots {
	cursor: pointer;
}

.transaction-listing-table .dateOuter {
	display: table-row;
}

.transaction-listing-table {
	margin: 0px;
	th,
	td {
		text-align: left;
		border: none;
		padding: 5px 10px;
		.btn {
			padding: 0px;
			outline: none !important;
			box-shadow: none !important;
			img {
				width: 20px;
			}
		}
	}
}

.dateContainer img {
	margin-right: 5px;
	position: relative;
	top: -1px;
}

.transaction-listing-table tr th:first-child,
.transaction-listing-table tr td:first-child {
	padding-left: 0;
}

.transaction-listing-table tr th:last-child,
.transaction-listing-table tr td:last-child {
	padding-right: 0;
}

.bank-card-wrapper .autopay:last-child {
	margin-bottom: 50px;
}

.text-center.modal-header {
	justify-content: center;
}

.transaction-listing-table td {
	padding: 12px 10px;
	border-bottom: solid 1px #f2f2f2;
}

.transaction-listing-table th {
	border-bottom: solid 2px #eee;
}

table .badge {
	width: auto !important;
	font-weight: 600 !important;
	font-size: 10px !important;
	padding: 5px 7px;
	height: auto !important;
	border-radius: 30px;
	min-width: 70px;
}

.radius-table table th:first-child {
	border-top-left-radius: 15px !important;
}

.radius-table table th:last-child {
	border-top-right-radius: 15px !important;
}

.radius-table table th {
	background-color: #414141;
	border: none;
}

.radius-table table,
.radius-table table thead {
	background-color: transparent !important;
}

.radius-table table td {
	border-bottom: solid 1px #f2f2f2;
	padding-top: 20px;
	padding-bottom: 20px;
}

.PageLayout.mt-0 {
	margin-top: 0px !important;
}

.message-mainouter .c-sidebar-minimizer {
	display: none;
}

.message-mainouter .msg-spacing {
	padding: 7rem 20px;
}

.message-create-btn-col {
	justify-content: flex-end;
}

.messaeOuter > span {
	display: inline-block;
	margin-right: 8px;
}

.message-expand .chat-cont * {
	text-align: left !important;
}

.send-btn .bgcmbtn {
	min-width: 80px;
}

.message-expand {
	padding-top: 20px;
}

// .csp-content-outer.c-wrapper {
//     padding-left: 0;
// }

@media (max-width: 1199px) {
	#root > .overflow {
		overflow-x: hidden;
	}
	.no-scroll {
		&.tablet {
			.cards-container {
				flex-wrap: wrap;
				& .icon-card {
					width: 31.33% !important;
					margin: 0px 1% 2% !important;
					.card-label {
						width: 100%;
					}
				}
			}
		}
	}
}
@media (max-width: 991px) {
	.no-scroll {
		max-width: 990px;
		width: 100%;
		.content-container {
			width: 100%;
			margin: 1rem 0;
			div.custom-input {
				margin-left: 0px;
				margin-right: 0px;
			}
			.edubox {
				padding: 0 10px !important;
			}
			&.loan-success {
				.trophy-image {
					left: 0px;
				}
				.text-container.ofr-spcing {
					padding-left: 0 !important;
					text-align: center;
				}
				.form-check {
					padding: 0;
					text-align: center;
				}
			}
		}
	}
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

.error-page {
	margin-top: 30px;
}

.error-page h4 {
	font-size: 16px;
	line-height: 25px;
}

.error-page h3 {
	font-size: 22px !important;
}

.error-page-content-container {
	text-align: left;
	max-width: 740px;
	margin: auto;
}

.error-page-head h3 {
	font-size: 36px !important;
	color: #000;
	margin: -15px 0 0 10px;
	text-align: center;
}

@media (max-width: 767px) {
	.error-page-content-container {
		margin: 0;
	}
	.error-page {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		margin-top: 0px;
	}
	.error-page img {
		float: none !important;
		margin: auto;
	}
	.error-page h3 {
		font-size: 17px !important;
	}
	.error-page h4 {
		font-size: 13px;
		line-height: 21px;
		text-align: center;
	}
	section {
		min-height: 1px;
	}
	.base-container.sign-in-box {
		margin: 3rem 0;
	}
	.content-container,
	#intro-section {
		flex: initial;
	}
	.overflow section {
		min-height: 1px !important;
	}
	.info-container {
		bottom: 10px;
		right: 15px;
	}
	.loan-success .cards-container.mzlnew .icon-card {
		width: 100% !important;
	}
	.no-scroll .content-container.loan-success .icon-card .card-label {
		height: auto;
		width: 100%;
	}
	.offer-list-col h1 {
		font-size: 23px;
	}
	.offer-list-col h3 {
		font-size: 16px;
	}
	.offer-list-col li > img {
		max-width: 65px;
	}
}

@media (max-width: 991px) {
	.error-page-content-container {
		margin: 0;
	}
	.error-page {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		margin-top: 0px;
	}
	.error-page img {
		float: none !important;
		margin: auto;
	}
	.error-page h3 {
		font-size: 17px !important;
		margin: 0 !important;
	}
	.error-page h4 {
		font-size: 13px;
		line-height: 21px;
		text-align: center;
	}
	body {
		background-color: #fff;
		padding-bottom: 50px;
		overflow-x: hidden;
	}
	.navbar .dropdown .btn img {
		max-width: 30px;
	}
	.header-action-icon {
		margin-left: 10px;
	}
	.dropdown-item {
		font-size: 13px;
		padding: 5px 10px;
	}
	.navbar-nav .dropdown-menu {
		padding: 3px 0;
	}
	.navbar {
		padding: 10px 10px !important;
		min-height: 55px;
	}
	.the-content-container.nw-costumer {
		padding: 70px 0px 0px !important;
	}
	.navbar-brand img {
		max-height: 25px;
		width: auto !important;
		margin: -4px 0 0 4px;
	}
	.navbar .navbar-nav .alert {
		padding: 5px 10px;
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		margin: 0;
	}
	.user-name-container h1 {
		font-size: 16px;
	}
	.user-name-container h4 {
		font-size: 14px;
		color: #8a8787;
	}
	.create_new button {
		padding: 7px 15px;
		font-size: 13px;
	}
	.user-info-app h3 {
		font-size: 16px;
		margin-bottom: 10px;
	}
	.user-info-app {
		margin-top: 1.5rem;
	}
	.nw-costumer .noCurrent {
		margin-bottom: 10px;
		font-size: 17px;
	}
	.cHeader {
		padding: 0.5rem 12px 0;
	}
	.title_pst {
		padding-top: 10px;
	}
	.c-sidebar-minimizer {
		top: -50px;
		right: -50px;
		display: block !important;
		z-index: 111111111;
	}
	.top_navOuter .bars {
		display: none;
	}
	.fixed-top {
		z-index: 1;
	}
	.c-sidebar .c-sidebar-minimizer::before {
		background-size: 30px;
	}
	.c-sidebar.c-sidebar-dark.c-sidebar-lg-show.c-sidebar-fixed.c-sidebar-minimized.Sidebar {
		margin-left: 0;
	}
	.c-sidebar-minimized .c-sidebar-minimizer {
		right: 205px;
	}
	.PageLayout {
		padding: 0 !important;
		margin-top: 70px !important;
	}
	.c-wrapper {
		padding: 0;
	}
	.contain_body .form-group label {
		margin: 0;
		font-size: 13px;
	}
	.form-group {
		margin-bottom: 5px;
	}
	.form-control-plaintext {
		font-size: 13px;
	}
	.nw-costumer > div:first-child {
		padding-left: 0;
		padding-right: 0;
	}
	.file-card .file-status {
		padding: 3px 10px;
	}
	.gnrl-documents .nw-costumer .file-card {
		padding: 10px 10px;
	}
	.file-card .main h5 {
		margin: 10px 0 0px;
		color: black;
	}
	.user-info-app h3 span {
		font-size: 13px;
		display: block;
		width: 100%;
		text-align: center;
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.legal_documents .legal-documents {
		width: 100%;
	}
	.legalDoc_main .main button {
		padding: 10px 25px;
		font-size: 14px;
		margin-top: 20px;
	}
	.legal_documents .file-card .main {
		padding: 20px 10px;
	}
	#page-section {
		padding: 70px 12px 0px !important;
	}
	.page-title {
		font-size: 16px;
	}
	.the-content-container.nw-costumer {
		padding: 70px 10px 0px !important;
	}
	.bankInfo-outer .bank-card-wrapper {
		margin-top: 10px;
	}
	html:not([dir="rtl"]) .modal-header .close {
		padding: 0;
		border: none !important;
		font-size: 23px;
		right: 20px;
		top: 20px;
	}
	.cCard.sumry-title.nwsmry.mt-0 {
		margin-top: 20px !important;
	}
	.tbl-space .title {
		font-size: 16px;
		line-height: normal;
		margin-bottom: 10px;
	}
	.radius-table .table {
		min-width: 700px;
	}
	.table-responsive table.table.table-hover thead tr th,
	.table-responsive table.table.table-hover td {
		font-size: 12px !important;
		padding: 5px 10px !important;
	}
	.tbl-space.noappfound {
		padding-top: 70px !important;
	}
	.mini-statement-table th,
	.mini-statement-table td {
		font-size: 12px;
	}
	.mini-statement-table {
		min-width: 900px;
		margin-top: 15px;
	}
	table h2,
	table .h2 {
		font-size: 17px;
	}
	ul.message-wrapper {
		padding: 0;
	}
	.message-mainouter .msg-spacing {
		padding: 70px 0px 0px;
	}
	.messaeOuter {
		flex-wrap: nowrap;
		position: relative;
	}
	.messaeOuter .name-title {
		font-size: 13px;
		line-height: normal;
	}
	.messaeOuter > span {
		display: inline-block;
		margin-right: 0;
	}
	.messaeOuter .timemes {
		position: absolute !important;
		right: 0;
		top: 5px;
	}
	.navbar .navbar-nav .alert {
		font-size: 12px;
	}
	.mess-navs li a.nav-link {
		padding: 0 10px;
	}
	[data-letters]::before {
		font-size: 12px;
		width: 30px;
		height: 30px;
		color: white;
		align-items: center;
		justify-content: center;
	}
	ul.message-wrapper li {
		padding: 10px 0 10px 0;
	}
	.messaeOuter .name-title {
		padding-top: 0;
	}
	.message-expand {
		padding: 0 10px;
	}
	.chat-btn {
		padding: 0;
	}
	.chat-btn h4 {
		font-size: 16px;
		margin-bottom: 10px;
	}
	.content-outermsg .chat-btn .form-control,
	.content-outermsg textarea {
		font-size: 13px;
		padding: 5px 10px;
		border-radius: 5px;
	}
	.message-mainouter .content-outermsg {
		padding: 10px 0 0 0;
		background: #f9f9f9;
	}
	.messaeOuter .msg-content {
		padding-right: 10px;
	}
	.message-expand .msg-content p {
		line-height: 20px;
	}
	section {
		-moz-transform: scale(1);
		zoom: 0 !important;
	}
	#page-section {
		padding: 20px 10px 0px !important;
	}
	.save-exit-button-container {
		justify-content: center;
		margin-bottom: 10px;
	}
	.product-title {
		font-size: 20px !important;
		margin-bottom: 20px;
	}
	.no-scroll.product-sectionOuter .icon-card,
	.no-scroll.tiles-3 .icon-card,
	.no-scroll.tablet .cards-container .icon-card,
	.no-scroll .content-container .icon-card {
		width: 50% !important;
		margin: 0 0px 10px 0 !important;
		display: inline-block;
		padding: 0 5px;
	}
	.no-scroll .card-label .inboxtitle {
		font-size: 14px !important;
		line-height: 16px !important;
	}
	.no-scroll .content-container .amount-input .custom-input {
		padding-left: 40px;
	}
	.form-check {
		-moz-transform: scale(1);
		margin: 0 0 0 0 !important;
	}
	.form-check-label {
		margin-bottom: 0;
		font-size: 13px;
	}
	.base-container .footer {
		font-size: 13px;
	}
	.base-container .cta-button {
		margin-top: 0;
	}
	.rsw_2f .save-exit-button-container {
		justify-content: space-between;
	}
	.rsw_2f .save-exit-button-container img {
		max-width: 120px !important;
	}
	.save-exit-button {
		border: 2px solid black;
		font-weight: 800;
		padding: 5px 8px;
		font-size: 13px;
	}
	#intro-section {
		padding-top: 20px;
	}
	.mzn-support #intro-section h3 {
		font-size: 16px;
	}
	.mzn-support #intro-section h1 {
		font-size: 22px;
		margin-bottom: 5px;
	}
	#intro-section h4 {
		font-size: 16px;
	}
	#intro-section .custom-button {
		margin-top: 10px;
	}
	.no-scroll .content-container .text-container {
		padding: 0px 0rem !important;
	}
	.no-scroll .content-container .text-container h3 {
		font-size: 16px;
	}
	.custom-button.disabled {
		background: #d9d9d9 !important;
		color: gray;
	}
	.no-scroll .content-container .text-container h1 {
		font-size: 18px;
	}
	.cards-container {
		flex-direction: unset;
	}
	.no-scroll .content-container .location-search-container .search-input {
		min-width: 100%;
	}
	.no-scroll
		.content-container
		.location-search-container
		.search-input::after {
		right: 9px;
		top: 12px;
	}
	.autocomplete-dropdown-container {
		margin-left: 0px !important;
	}
	.autocomplete-dropdown-container * {
		font-size: 12px;
	}
	.cards-container {
		flex-wrap: wrap;
	}
	.no-scroll .content-container .text-container h1 {
		text-align: center;
	}
	.no-scroll .content-container .address-input-container .input-container {
		min-width: 100%;
	}
	// .eduction-new .input-container .edubox {
	//     width: 49%;
	//     padding: 0 !important;
	// }
	.no-scroll .card-label {
		width: 100%;
	}
	.spaced.equifax h1 {
		font-size: 25px;
		margin-bottom: 10px;
	}
	.spaced.equifax h2 {
		font-size: 14px;
		font-weight: 600;
	}
	.spaced.equifax h4 {
		font-size: 14px;
	}
	.no-scroll.tablet .text-container h1 {
		font-size: 16px !important;
		line-height: 20px;
		margin-bottom: 20px;
	}
	.mess-navs.message-wrapper h2 {
		text-align: center;
		font-size: 15px;
		margin-top: 20px;
	}
	.no-scroll .content-container .text-container h3 {
		text-align: center;
		margin-bottom: 20px;
	}
	.input-container {
		width: 100%;
		max-width: 300px;
		margin: auto;
		min-width: 1px !important;
	}
	// .multiple-mobile-inputs {
	//     width: 100%;
	//     max-width: 100%;
	//     flex-direction: row;
	//     flex-wrap: wrap;
	//     justify-content: space-between;
	// }
	// .multiple-mobile-inputs > * {
	//     width: 49% !important;
	// }
	// .multiple-mobile-inputs .custom-label {
	//     font-size: 13px;
	// }
	.no-scroll .content-container #loan-amount {
		width: calc(100% - 0px);
	}
	.loan-slider {
		margin: 60px auto 0;
		padding-left: 0;
		width: calc(100% - 16px);
		max-width: 280px;
	}
	.no-scroll .content-container .amount-input {
		width: 100%;
		margin: auto;
		max-width: 300px;
	}
	.input-container .amount-input {
		width: 100% !important;
	}
	.address-input-container .input-container {
		max-width: 325px !important;
	}
	.input-container div {
		padding: 0;
		width: 100%;
	}
	// .eduction-new .input-container > * {
	//     width: 49% !important;
	//     padding: 0 5px !important;
	// }
	// .eduction-new .input-container {
	//     flex-wrap: wrap;
	//     flex-direction: row;
	// }
	.address-input-container .input-container {
		flex-wrap: wrap;
		flex-direction: row;
	}
	#page-section {
		background-color: transparent !important;
	}
	.custom-label {
		font-size: 13px;
	}
	.form-check {
		zoom: 0;
	}
	#intro-section .text-container {
		margin: 0;
		padding: 0;
	}
	body {
		padding-bottom: 10px;
	}
	.base-container {
		padding: 1rem 2rem !important;
	}
	.no-scroll .content-container.loan-success .icon-card .card-label {
		height: auto;
		width: 100%;
	}
	.loan-success .form-check {
		text-align: left !important;
		padding-left: 25px !important;
	}
	.button-container.offers-button > button:first-child {
		margin-bottom: 15px;
	}
	.no-scroll {
		max-width: 100%;
		width: 100%;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.Sidebar {
		margin-top: 56px !important;
	}
	.navbar {
		padding: 10px 10px 10px 30px !important;
	}
	.c-sidebar-minimizer {
		top: -53px;
	}
	.gnrl-documents .nw-costumer .file-card {
		width: 50%;
	}
	.page-title,
	.user-name-container h1,
	.tbl-space .title {
		font-size: 22px;
	}
	.tbl-space .title {
		margin-top: 70px;
	}
	.table-responsive table.table.table-hover thead tr th,
	.table-responsive table.table.table-hover td {
		font-size: 12px !important;
		padding: 10px 10px !important;
	}
	.message-expand .msg-content p {
		font-size: 13px;
	}
	.message-mainouter .content-outermsg {
		width: 100%;
	}
	.no-scroll.product-sectionOuter .icon-card,
	.no-scroll.tiles-3 .icon-card,
	.no-scroll.tablet .cards-container .icon-card,
	.no-scroll .content-container .icon-card {
		width: 30.333% !important;
		margin: 0 0px 10px 0 !important;
		display: inline-block;
		padding: 0 5px;
	}
	.no-scroll .content-container #loan-amount {
		width: 100%;
		margin: 0;
	}
	.no-scroll .content-container .amount-input.left-10::after {
		top: 8px;
		left: 24px;
	}
	.no-scroll .content-container .text-container h3 {
		font-size: 16px;
		text-align: center;
		margin-bottom: 20px;
	}
	.autocomplete-dropdown-container {
		margin-left: 15px !important;
	}
	.input-container div {
		// padding: 0;
		width: 100%;
	}
	.no-scroll .content-container .amount-input .custom-input {
		padding-left: 40px;
		// margin: 0;
	}
	.custom-label {
		// padding-left: 0;
	}
	.no-scroll .content-container .amount-input::after {
		left: 34px;
	}
	.address-input-container .input-container .edubox {
		padding: 0 10px !important;
	}
	.input-container {
		max-width: 85%;
	}
	.address-input-container .input-container {
		max-width: 600px !important;
	}
	.multiple-mobile-inputs.input-container div {
		padding: 0 5px;
		width: 100%;
	}
	// .no-scroll .content-container .amount-input {
	//     max-width: 100%;
	// }
	.full-width {
		max-width: 100% !important;
	}
	.input-container.refinance {
		max-width: 400px !important;
	}
	.no-scroll.product-sectionOuter .icon-card,
	.no-scroll.tiles-3 .icon-card,
	.no-scroll.tablet .cards-container .icon-card,
	.no-scroll .content-container .icon-card {
		width: 25% !important;
		margin: 0 0px 10px 0 !important;
		display: inline-block;
		padding: 0 5px;
	}
	.input-container.medium-input {
		max-width: 400px;
	}
	.no-scroll .content-container .amount-input .custom-input {
		padding-left: 28px;
	}
	.content-container {
		padding: 0 15px;
	}
	.no-scroll .content-container .amount-input.left-10::after {
		top: 8px;
		left: 14px;
	}
	.no-scroll .content-container .amount-input::after {
		left: 30px;
	}
	.error-page h3 {
		font-size: 25px !important;
		margin: 0 !important;
	}
	.error-page-head img {
		max-width: 75px;
	}
	.plaid-main-container h5 {
		font-size: 15px;
	}
	.plaid-main-container, .plaid-main-container h6 {
		font-size: 12px;
	}
	.plaid-main-container h4 {
		font-size: 14px;
	}
	.plaid-main-container .right-content {
		padding: 0 13px !important;
	}
	.plaid-main-container .button-container {
		position: fixed;
		left: 0;
		bottom: 0px;
		padding: 10px 10px !important;
		width: 100%;
		background: #fff;
	}
	.plaid-main-container .button-container .custom-button {
		font-size: 12px;
		width: auto;
		padding: 6px 10px;
	}
	.plaid-main-container .button-container p {
		font-size: 12px;
		margin-top: 5px !important;
	}
	.plaid-main-container .button-container p img {
		width: 13px;
	}
	.plaid-main-container {
		margin-bottom: 60px !important;
	}
}

@media (min-width: 992px) and (max-width: 1300px) {
	.the-content-container.nw-costumer,
	.gnrl-documents .nw-costumer,
	.legal_documents .nw-costumer {
		padding: 7rem 20px 7rem 70px;
	}
	.navbar .navbar-nav .alert {
		font-size: 13px;
	}
	.application-leftcontent + .pb-sm-3.col-md-4 {
		padding-left: 0;
	}
	.cMain {
		padding: 15px 10px;
	}
	.title_pst {
		padding-top: 10px;
	}
	.btn-copylnik {
		right: 7px;
	}
	.create_new button {
		padding: 8px 15px;
		font-size: 14px;
	}
	.gnrl-documents .nw-costumer .file-card {
		width: 100%;
	}
	.legal_documents .legal-documents {
		width: 100%;
	}
	.legal_documents .user-info-app h3 span {
		font-size: 14px;
		padding: 5px 10px !important;
	}
	.bankInfo-outer #page-section {
		padding-right: 10px;
	}
	.tbl-space.noappfound {
		padding: 8rem 20px 7rem 150px;
	}
	.mini-statement-table th,
	.mini-statement-table td {
		font-size: 12px;
	}
	.mini-statement-table {
		min-width: 900px;
		margin-top: 15px;
	}
	.tbl-space {
		padding-right: 10px;
	}
	.message-expand .msg-content p,
	.message-expand .msg-content span,
	.message-expand .msg-content small {
		font-size: 13px;
		line-height: 18px;
	}
	.messaeOuter .timemes {
		position: absolute !important;
		right: 0;
		top: 5px;
	}
	.messaeOuter {
		position: relative;
		flex-wrap: nowrap;
	}
	.chat-btn {
		padding: 0;
	}
	.message-expand {
		padding: 0;
	}
	.content-outermsg {
		width: 100%;
	}
	.no-scroll .content-container {
		margin: 28px 0 0px;
	}
	#page-section {
		padding-left: 12px 32px !important;
	}
}

@media (min-width: 1200px) and (max-width: 1300px) {
	.gnrl-documents .nw-costumer .file-card {
		width: 48%;
	}
	.tbl-space.noappfound {
		padding: 8rem 20px 7rem 125px;
	}
}

@media (min-width: 1200px) {
	.no-scrolls .text-container h3 {
		font-size: 1.53125rem !important;
	}

	.no-scrolls .text-container h1 {
		font-size: 2.1875rem !important;
	}

	.no-scrolls .text-container h4 {
		font-size: 1.3125rem;
	}
	.info-container.hoverable-container {
		padding: 80px 0 0 130px;
	}

	.info-container.hoverable-container .tool-tip {
		right: 0;
		bottom: 20px;
	}
}

.custom-notification-toast {
	background: #0676ed !important;
	border-radius: 12px !important;
}

.custom-notification-toast .Toastify__toast-body {
	font-size: 14px;
	font-weight: 800;
	display: flex;
	align-items: center;
}

.custom-notification-toast .Toastify__progress-bar {
	background-color: #0676ed;
}

.custom-notification-toast .Toastify__toast-body::before {
	content: "";
	display: table;
	height: 34px;
	width: 34px;
	background: #5aa2ee;
	border-radius: 50%;
	margin-right: 12px;
}

.Toastify__toast-container.Toastify__toast-container--top-right {
	max-width: 440px !important;
	width: 100%;
}

.custom-notification-toast .Toastify__close-button {
	opacity: 1;
	margin-top: 12px;
	margin-right: 10px;
}

.custom-notification-toast .Toastify__close-button svg {
	width: 12px;
}

.news-style-one .image-box img {
	height: 100%;
}

.news-style-one .image-box {
	height: 300px;
	overflow: hidden;
}

.blogs-list {
	display: flex;
	flex-wrap: wrap;
}

.news-style-one .lower-content {
	padding: 10px 0px 0px !important;
}

@media (min-width: 1200px) {
	.vertical-middle-page {
		min-height: calc(100vh - 60px);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: -30px;
	}
	.vertical-middle-page .content-container {
		margin-bottom: 0;
	}
	.vertical-middle-page .button-container {
		padding-bottom: 0;
	}
	.vertical-middle-page .custom-back-button {
		margin-bottom: 0px;
	}
}

button[disabled=""] {
	background-color: #eeeeee !important;
	color: white !important;
	pointer-events: none !important;
}

.disabled-menu-item {
	position: relative;
	opacity: 0.3;
}

.disabled-menu-item * {
	pointer-events: none;
}

.disabled-menu-item::after {
	content: "";
	display: table;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	cursor: not-allowed;
}

.error-text-message {
	color: #ff9300;
	font-weight: 700;
	text-align: center;
	font-size: 20px;
	width: 100%;
	margin: 70px 0 0 0;
}

.password-checker {
	margin-top: 20px;
}

@media (max-width: 767px) {
	.no-messages-text {
		padding: 10px 0 40px 0px;
	}
}

@media (max-width: 992px) {
	.error-text-message {
		font-size: 14px;
		margin: 30px 0 0 0;
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	.news-style-one .image-box {
		height: auto !important;
	}
	.news-style-one .image-box img {
		height: auto !important;
	}
	.news-style-one {
		margin-bottom: 30px !important;
	}
	.news-style-one .lower-content .post-meta {
		font-size: 13px !important;
		margin-bottom: 5px !important;
	}
	.news-style-one .lower-content h3 {
		font-size: 16px !important;
	}
	.news-style-one .text span {
		font-size: 14px !important;
	}
	.blog-detail-col {
		padding: 30px 15px 100px 15px !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.blog-detail-col .image-column {
		width: 100%;
		height: auto;
		margin: 0 0 10px 0 !important;
	}
	.blog-detail-col h1 {
		font-size: 20px !important;
		font-weight: 600;
	}
	.blog-detail-col .text p {
		font-size: 14px;
	}
	.blog-header-col {
		margin: 0 0 10px !important;
	}
}

.custom-notification-sweetalert .modal-footer .pad-btn {
	padding: 8px 15px;
}

.custom-notification-sweetalert .warning-modal-text {
	color: #000;
	font-weight: 600;
}

.custom-notification-sweetalert .warning-modal-text span {
	color: #ffa500;
}

.equifax-question-box .card-label {
	width: 200px;
	height: 180px;
}

.signup-header-text {
	font-size: 15px;
	max-width: 330px;
	text-align: center;
	font-weight: 600;
	color: #ff9e00;
}
.icon-card-disabled {
	opacity: 0.8;
	cursor: not-allowed;
}

.icon-card-disabled * {
	cursor: not-allowed;
}

#color-indicators {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 0.2rem;
}
#color-indicators span {
	height: 2px;
}
span.valid:nth-child(1) {
	background-color: rgb(239, 72, 54);
}
span.valid:nth-child(2) {
	background-color: rgb(246, 180, 77);
}
span.valid:nth-child(3) {
	background-color: rgb(43, 144, 239);
}
span.valid:nth-child(4) {
	background-color: rgb(37, 194, 129);
}

li.valid {
	color: green;
	font-weight: 600;
}

.password-checker-container {
	margin: 10px 0 0 0;
}

.password-checker-container #color-indicators {
	margin: 0 0 10px 0;
}

.password-checker-text {
	font-weight: 600;
	margin: 0 0 3px 0;
}

.password-checker-list {
	font-size: 12.5px;
	font-weight: 500;
	color: #000;
}

.password-checker-list .fa {
	display: none;
}

.password-checker-list .valid .fa {
	display: inline-block;
}

.cCard.sumry-title .CircularProgressbar .CircularProgressbar-text {
	font-size: 21px;
}

.lg-edubox {
	min-width: 330px;
}

.lg-edubox .custom-input > div > div {
	height: 38px;
	min-height: 1px;
}

.lg-edubox .custom-input > div > div > div:first-child {
	height: 100%;
	min-height: 1px;
}

.lg-edubox .custom-input > div > div > div:last-child {
	height: initial;
	min-height: 1px;
}

.modal-closer-custom {
	position: absolute;
	top: -10px;
	right: -10px;
	height: 35px;
	width: 35px;
	border-radius: 50%;
	z-index: 1111111111;
	display: flex;
	background: #ff9e00;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 20px;
	cursor: pointer;
}

.third-datepicker input {
	margin: 0 !important;
	background: transparent !important;
	border: none !important;
	box-shadow: none !important;
	width: 100% !important;
	max-width: 100% !important;
	outline: none !important;
}

.third-datepicker .react-datepicker-wrapper,
.third-datepicker .react-datepicker__input-container {
	width: 100% !important;
}

.third-datepicker .react-datepicker {
	width: 300px !important;
	margin-left: -12px;
}

.third-datepicker .react-datepicker__month-container {
	width: 100% !important;
}

.third-datepicker .react-datepicker__header.react-datepicker-year-header {
	padding: 10px 0;
}

.third-datepicker .react-datepicker__month-text {
	width: calc(100% / 3) !important;
	margin: 0 !important;
	padding: 12px 0 !important;
	font-weight: 400;
}

.third-datepicker .react-datepicker__month-text--keyboard-selected {
	background: #ff9300;
}

.third-datepicker * {
	outline: none !important;
}

.third-datepicker .react-datepicker__navigation {
	top: 12px !important;
}

.third-datepicker {
	background-image: url("../assets/icons/cal-icon.png");
	background-repeat: no-repeat;
	background-size: 20px;
	background-position: right 15px center;
}

.gnrl-documents .file-card .main {
    display: table;
    width: 100%;
}

.doc-info-text {
    margin: 0 0 8px 0;
    float: none;
    text-align: center;
    min-height: 72px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.doc-info-text p {
    margin: 0 0 5px 0px;
}

.doc-info-text p {
    margin: 0 0 3px 0px;
    font-weight: bolder;
    text-decoration: underline;
    font-size: 14px;
}

@media (max-width: 767px) {
	.title-page-note {
		font-size: 12px;
		text-align: center;
	}
} ;

.overflow .autoHeight{
	min-height: 1px !important;
}

@media (min-width: 800px) {
	.text-note-offer {
		font-size: 17px;
	}
}