// Variable overrides
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap");

//Sidebar Overrides
$sidebar-dark-nav-link-hover-bg: #ff9e00 !default;
$sidebar-dark-nav-link-active-bg: #ff9e00 !default;
$sidebar-dark-bg: #181819 !default;

//Shared Variables
$component-active-bg: #ff9e00 !default;

$font-family-base: "Montserrat", "sans-serif" !default;
